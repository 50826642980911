<template>
    <div>
        <!-- 问题咨询排名 / 设置统计分类-->
        <popup
            @closeEvent="closeEvent"
            v-if="statisticalClassifiVisible"
            class="statistical-classifi-visible"
        >
            <div slot="popup-name">
                {{ $t("statisticsUp.setStatisticClassifiTitle") }}
            </div>
            <div slot="popup-con">
                <div class="popup-container-self">
                    <div class="list-box">
                        <div class="title-top">
                            <div class="left-title-top">
                                {{ $t("statisticsUp.class") }}
                            </div>
                            <div class="right-title-top">
                                {{ $t("statisticsUp.setTypeDialogTip2") }}
                            </div>
                        </div>
                        <div class="scroll-box">
                            <div
                                class="item-statistical-classifi"
                                v-for="(
                                    item, index
                                ) in setStatisticalClassifi.questionTypeConfigs"
                                :key="index"
                            >
                                <el-input
                                    v-model.trim="item.name"
                                    :placeholder="
                                        $t('statisticsUp.classNamePla')
                                    "
                                ></el-input>
                                <el-cascader
                                    collapse-tags
                                    :ref="'changekeyWordRecommend' + index"
                                    :options="intentTree4Radio"
                                    :props="{
                                        multiple: true,
                                        value: 'id',
                                        label: 'name',
                                        emitPath: false,
                                        checkStrictly: true,
                                    }"
                                    :show-all-levels="false"
                                    :placeholder="
                                        $t('botIntent.searchOrSelectIntent')
                                    "
                                    v-model="item.intentIds"
                                    @change="
                                        changeIntentGuan($event, item, index)
                                    "
                                    filterable
                                >
                                </el-cascader>
                                <i
                                    v-show="
                                        setStatisticalClassifi
                                            .questionTypeConfigs.length > 1
                                    "
                                    class="el-icon-delete cursor"
                                    @click="confirmDeleteKeyWord(index)"
                                >
                                </i>
                            </div>
                        </div>
                        <div class="add-classifi-btn">
                            <div
                                class="add-btn guoran-tongyichicun-16-13-xinjian iconfont"
                                @click="
                                    setStatisticalClassifi.questionTypeConfigs.push(
                                        { name: '', intentIds: [], nodeList: null, ext: null }
                                    )
                                "
                            ></div>
                        </div>
                    </div>
                    <div class="statistical-rank">
                        <div class="statistical-rank-left">
                            {{ $t("statisticsUp.topSystemRank") }}
                        </div>
                        <div class="statistical-rank-right">
                            <el-input
                                v-model.trim="setStatisticalClassifi.topN"
                                min="1"
                                max="100"
                                :placeholder="$t('common.inputPlaceholder')"
                                type="number"
                                @change="changeFn"
                            ></el-input>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round class="cancel-btn">{{
                    $t("common.cancel")
                }}</el-button>
                <el-button
                    class="confirm-btn"
                    type="primary"
                    @click="submitForm('')"
                    round
                    style="padding: 0 !important"
                    >{{ $t("common.confirm") }}</el-button
                >
            </div>
        </popup>
    </div>
</template>

<script>
import popup from "../../../components/popup.vue";
import _ from "lodash";
import {
    nodeListToIntents,
    convertToStringArray,
    toIntentTree4Radio,
    formatSaveConfig,
} from "./intentTreeCascader";
export default {
    components: {
        popup,
    },
    data() {
        return {
            nodeTree: [],
            intentTree4Radio: [],
            setStatisticalClassifi: {
                questionTypeConfigs: [
                    {
                        name: "",
                        intentIds: [],
                        ext: {},
                    },
                ],
                topN: 10,
            },
            allIntent: [],
        };
    },
    props: {
        activeScopeValue: {
            type: String,
            default: "",
        },
        activeScopeType: {
            type: String,
            default: "",
        },
        statisticalClassifiVisible: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        statisticalClassifiVisible(n) {
            if (n) {
                this.allIntent = [];
                this.getIntentList();
                this.getData();
            }
        },
    },
    methods: {
        changeFn(value) {
            this.setStatisticalClassifi.topN =
                this.setStatisticalClassifi.topN.replace(/[^0-9]/g, "");
            if (value < 1) {
                this.setStatisticalClassifi.topN = 1;
            }
            if (value > 100) {
                this.setStatisticalClassifi.topN = 100;
            }
        },
        getData() {
            this.FetchGet(
                this.requestUrl.statistics.questionType +
                    this.formatterActiveScopeValue(this.activeScopeValue)
            ).then((res) => {
                if (res.code === "0") {
                    this.setStatisticalClassifi.questionTypeConfigs =
                        res.data.questionTypeConfigs;
                    this.setStatisticalClassifi.questionTypeConfigs.forEach(
                        (item) => {
                            if (item.nodeList) {
                                let tampIntentIds = convertToStringArray(
                                    item.nodeList
                                );
                                item.intentIds = [...tampIntentIds];
                            } else {
                                // 兼容老版本配置回显
                                item.intentIds.forEach(
                                    (intent, intentIndex) => {
                                        item.intentIds[intentIndex] =
                                            "INTENT~" + intent;
                                    }
                                );
                            }
                        }
                    );
                    this.setStatisticalClassifi.topN = res.data.topN || 10;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        getIntentList() {
            let scope = this.formatterActiveScopeValue(this.activeScopeValue);
            this.FetchGet(
                this.requestUrl.statistics.getIntentTreeByScope + scope,
                { scopeType: this.activeScopeType }
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.nodeTree = [...res.data];
                        this.intentTree4Radio = toIntentTree4Radio(res.data);
                        this.handlerIntent(this.nodeTree);
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        handlerIntent(list) {
            list.forEach((item, index) => {
                if (item.type === "INTENT") {
                    this.allIntent.push(item.id);
                } else {
                    this.handlerIntent(item.children || []);
                }
            });
        },
        formatterActiveScopeValue(activeScopeValue) {
            return activeScopeValue.split("__")[0];
        },
        confirmDeleteKeyWord(index) {
            this.setStatisticalClassifi.questionTypeConfigs.splice(index, 1);
        },
        changeIntentGuan(datas, item, index) {
            item.intentIds = [...item.intentIds];
        },
        closeEvent() {
            this.$emit("update:statisticalClassifiVisible", false);
            this.setStatisticalClassifi = {
                questionTypeConfigs: [
                    {
                        name: "",
                        intentIds: [],
                        ext: {},
                    },
                ],
                topN: 10,
            };
        },
        submitForm() {
            let flag = true;
            console.log(this.setStatisticalClassifi.questionTypeConfigs);
            if (
                !this.setStatisticalClassifi.questionTypeConfigs ||
                this.setStatisticalClassifi.questionTypeConfigs.length === 0
            ) {
                flag = false;
                this.$message.closeAll();
                this.$message.error(this.$t("statisticsUp.hasOneClassMsg"));
            } else {
                this.setStatisticalClassifi.questionTypeConfigs.forEach(
                    (item, index) => {
                        if (item.name === "") {
                            flag = false;
                            this.$message.closeAll();
                            this.$message.error(
                                this.$t("statisticsUp.classNameRequired")
                            );
                        }
                        if (flag) {
                            if (
                                item.intentIds.length === 0 ||
                                !item.intentIds
                            ) {
                                flag = false;
                                this.$message.closeAll();
                                this.$message.error(
                                    this.$t(
                                        "statisticsUp.associationIntentionRequired"
                                    )
                                );
                            }
                            // let _flags = false;
                            // this.allIntent.forEach((v, i) => {
                            //     item.intentIds.forEach((a, b) => {
                            //         if (v == a) {
                            //             console.log();
                            //             _flags = true;
                            //         }
                            //     });
                            // });
                            // if (!_flags) {
                            //     flag = false;
                            //     this.$message.closeAll();
                            //     this.$message.error(
                            //         this.$t(
                            //             "statisticsUp.associationIntentionRequired"
                            //         )
                            //     );
                            // }
                        }
                    }
                );
            }
            if (!/(^[1-9]\d*$)/.test(this.setStatisticalClassifi.topN)) {
                flag = false;
                this.$message.closeAll();
                this.$message.error(this.$t("statisticsUp.positiveNumbersMsg"));
            }
            let arr = [
                ...new Set(
                    this.setStatisticalClassifi.questionTypeConfigs.map(
                        (item) => item.name
                    )
                ),
            ];
            if (
                arr.length !=
                this.setStatisticalClassifi.questionTypeConfigs.length
            ) {
                flag = false;
                this.$message.closeAll();
                this.$message.error(
                    this.$t("statisticsUp.classNameDuplicationMsg")
                );
            }
            if (!flag) {
                return false;
            } else {
                let obj = JSON.parse(
                    JSON.stringify(this.setStatisticalClassifi)
                );
                obj.questionTypeConfigs = formatSaveConfig(obj.questionTypeConfigs, this.nodeTree);
                this.FetchPost(
                    this.requestUrl.statistics.questionConsult +
                        this.formatterActiveScopeValue(this.activeScopeValue) +
                        "?scopeType=" +
                        this.activeScopeType,
                    obj
                ).then((res) => {
                    if (res.code === "0") {
                        this.$message.closeAll();
                        this.$message.success(
                            this.$t("statisticsUp.classSetSuccess")
                        );
                        this.$emit("updateSetStatisticalClassifi");
                        this.closeEvent();
                    } else {
                        this.$message.error(res.message);
                    }
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.statistical-classifi-visible {
    /deep/ .el-input__inner {
        height: 38px !important;
    }
    /deep/ #popup-assembly {
        width: 796px;
    }
    .popup-container-self {
        max-height: 728px;
        background: #fbfcfd;
        border-radius: 5px;
        width: 100%;
        color: #000000;
        font-size: 14px;
    }
    .list-box {
        margin-bottom: 20px;
        width: 100%;
        .title-top {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            height: 40px;
            background: #f6f8fd;
            border-radius: 5px 5px 0px 0px;
            padding: 0 13px;

            .left-title-top {
                width: 220px;
                font-size: 14px;
                text-align: left;
            }
            .right-title-top {
                width: 65%;
                font-size: 14px;
                text-align: left;
                margin-left: 10px;
            }
        }
        .scroll-box {
            max-height: 350px;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .item-statistical-classifi {
            padding: 0 13px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .el-input {
                width: 220px;
                margin-right: 14px;
            }
            /deep/ .el-cascader {
                width: 423px;
                margin: 0 10px;
                .el-cascader__tags {
                    .el-tag {
                        max-width: 80%;
                        height: 24px;
                        background: #edf0f8;
                        border-radius: 3px;
                        display: flex;
                        align-items: center;
                        align-items: center;
                        font-size: 13px;
                        color: #333333;
                        &:first-child {
                            background: #366aff;
                            color: #ffffff;
                            .el-tag__close {
                                font-size: 14px;
                                background-color: transparent;
                                &:hover {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                    .el-cascader__search-input {
                        display: none;
                    }
                }
            }
            .el-icon-delete {
                color: #366aff;
                font-size: 18px;
                margin-left: 9px;
            }
        }
        .add-classifi-btn {
            display: flex;
            justify-content: center;
            .add-btn {
                width: 31px;
                height: 31px;
                background: #ffffff;
                border: 1px solid #d5e0ff;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                cursor: pointer;
                color: #366aff;
            }
        }
    }
    .statistical-rank {
        display: flex;
        align-items: center;
        padding-bottom: 14px;
        padding: 14px;
        .statistical-rank-left {
            width: 70px;
            margin-right: 15px;
            white-space: nowrap;
        }
        .statistical-rank-right {
            width: calc(100% - 95px);
            /deep/ .el-input {
                .el-input__inner {
                    line-height: 1px !important;
                }
            }
        }
    }
}
</style>