<template>
    <div class="bot-heathly-drawer" v-if="botHeathlyDrawerVisible" :style="$i18n.locale === 'en' ? 'width:520px' : ''">
		<!-- <el-drawer
            class="bot-heathly-drawer"
            key="bot-heathly-drawer"
            show-close
            size="420px"
            direction="rtl"
            :visible.sync="botHeathlyDrawerVisible"
            :close-on-press-escape="false"
            :wrapperClosable="false"
            :with-header="true"> -->
        <!-- <div class="bot-heathly-box" v-if="botHeathlyDrawerVisible"> -->
            <div class="bot-heathly-drawer-header">
                <div class="title">
                    <template v-if="drawerType === 'heathly'">
                        <svg t="1665319278665" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="29088" width="16" height="16">
                            <path 
                                d="M512 0C229.376 0 0 229.376 0 512c0 70.656 14.336 137.728 39.936 199.168l264.704-339.456 185.856 190.464 130.56-164.864L541.184 317.44l318.464-85.504-85.504 318.464-80.384-80.384-199.68 242.176-180.736-185.344-219.136 280.576C187.392 938.496 339.456 1024 512 1024c282.624 0 512-229.376 512-512S794.624 0 512 0z" 
                                p-id="29089"
                                fill="#FF8063">
                            </path>
                        </svg>
                        <span>{{$t('botHeathlyDrawer.title')}}</span>
                    </template>
                    <template v-else>
                        <div class="title">
                            <i class="el-icon-arrow-left" @click="backHeathly"></i>
                            <span>{{$t('botHeathlyDrawer.title2')}}</span>
                            <div class="export-button" @click="openSeniorFilter">{{$t('botHeathlyDrawer.screen')}}</div>
                        </div>
                    </template>
                </div>
                <div class="right-box">
                    <div class="heathly-drawer-header-bot-list">
                        <div style="position: relative">
                            <img
                                style="
                                    width: 16px;
                                    height: 16px;
                                    position: absolute;
                                    left: 10px;
                                    top: 8px;
                                    z-index: 5;
                                "
                                class="imgbtype"
                                :src="getBotProfilePhoto"
                                alt=""
                            />
                            <el-select
                                v-model="activeBot"
                                :placeholder="$t('botHeathlyDrawer.selectBot')"
                                @change="selectBot"
                                size="small"
                                :disabled="disabled"
                            >
                                <el-option
                                    v-for="item in botList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                >
                                    <div
                                        class="uf-top-bot-select"
                                        style="display: flex; align-items: center"
                                    >
                                        <img
                                            height="16px"
                                            style="margin-right: 6px; margin-top: -3px"
                                            :src="item.botProfilePhoto"
                                            alt=""
                                            srcset=""
                                        />
                                        <span style="float: left">{{ item.name }}</span>
                                    </div>
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <el-button v-if="drawerType === 'heathly'" type="primary" class="export-button" @click="exportData" :loading="exportBtnLoading">{{$t('botHeathlyDrawer.export')}}</el-button>
                    <div :class="['close',drawerType === 'heathly' ? '' : 'detail']">
                        <span @click="handleClose" class="el-icon-close"></span>
                    </div>
                </div>
                
            </div>
             <!-- <div class="bot-heathly-drawer-header">
                    <div class="title">
                        <i class="el-icon-arrow-left" @click="backHeathly"></i>
                        <span>意图详情</span>
                        <div class="export-button" @click="openSeniorFilter">高级筛选</div>
                    </div>
                    <div class="close">
                        <span @click="handleClose" class="el-icon-close"></span>
                    </div>
                </div> -->
            <template v-if="drawerType === 'heathly'" >
                <div class="statistics-content">
                    <div class="score-boxs">
                        <div class="progress-box"  v-if="isChecking">
                            <div style="display:flex;align-items: center;">
                                <img class="progress-img" src="../../../assets/images/checking.png" alt="" />
                                <div class="right-progress">
                                    <div class="text">{{$t('botHeathlyDrawer.detecting')}}</div>
                                    <el-progress :percentage="progress" :show-text="false" :stroke-width="5"></el-progress>
                                </div>
                            </div>
                            <div class="re-check" @click="stopCheck">{{$t('botHeathlyDrawer.pauseDetection')}}</div>
                        </div>
                        <div class="score-box" v-else>
                            <healthyScore :score="totalScore" type="intent"></healthyScore>
                            <div class="re-check" @click="reCheckAgain">{{$t('botHeathlyDrawer.retest')}}</div>
                        </div>
                    </div>
                    <div class="list-box">
                        <div class="list-header">
                            <div class="detection-item">{{$t('botHeathlyDrawer.detectionItems')}}</div>
                            <div class="standard">{{$t('botHeathlyDrawer.standard')}}</div>
                            <div class="operation">{{$t('botHeathlyDrawer.operation')}}</div>
                        </div>
                        <div class="list-content">
                            <div class="item" v-for="(item,index) in tableData" :key="index">
                                <div class="detection-item-text">{{item.name}}</div>
                                <div class="standard-text">
                                    <i class="el-icon-circle-close" v-if="!item.showChecked"></i>
                                    <i class="el-icon-circle-check" v-else></i>
                                    <div v-if="item.type === 'score'">
                                        <span class="now-num">{{item.beforeTotal}} </span>
                                        <span class="standard-num">
                                            <span v-if="item.name === $t('botHeathlyDrawer.noQualify') ||
                                            item.name === $t('botHeathlyDrawer.answerCompleteness') ||
                                            item.name === $t('botHeathlyDrawer.editAnswer')">/ {{item.afterTotal}}</span>
                                            <span v-else-if="item.name === $t('botHeathlyDrawer.unrecognized')">/ ≤ {{item.afterTotal}}</span>
                                            <span v-else>/ ≥ {{item.afterTotal}}</span>
                                        </span>
                                    </div>
                                    <div v-if="item.type === 'train'" class="train">
                                        <div>
                                            <span class="now-num">{{item.beforeTotal}}</span>
                                            <span class="standard-num"> / ≥ {{item.afterTotal}}</span>
                                        </div>
                                        <div class="week-times">{{$t('botHeathlyDrawer.weekTimes')}}</div>
                                    </div>
                                    <div v-if="item.type === 'status'">
                                        <span class="now-num">{{item.showChecked ? $t('botHeathlyDrawer.open') : $t('botHeathlyDrawer.close')}}</span>
                                        <span class="standard-num"> / {{$t('botHeathlyDrawer.open')}}</span>
                                    </div>
                                    <div v-if="item.type === 'use'">
                                        <span class="now-num" v-if="item.name === $t('botHeathlyDrawer.intelligentGuides')">{{item.showChecked ? $t('botHeathlyDrawer.configured') : $t('botHeathlyDrawer.notConfigured')}}</span>
                                        <span class="now-num" v-else>{{item.showChecked ? $t('botHeathlyDrawer.used') : $t('botHeathlyDrawer.notUsed')}}</span>
                                    </div>
                                    <div v-if="item.btnText !== $t('botHeathlyDrawer.use')" :class="['get-score',item.score > 0 ? 'green' : 'red']">{{item.score > 0 ? '+' : ''}}{{item.score}}{{$t('botHeathlyDrawer.score')}}</div>
                                </div>
                                <div :class="['operation-text',item.btnText === $t('botHeathlyDrawer.use') ? 'use-text' : '']" @click.stop="btnOperation(item,index)">{{item.btnText}}</div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="list-box-bottom">
                        <div class="list-header">
                            <div class="answer-item">
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="$t('botHeathlyDrawer.answerTip')"
                                    placement="bottom">
                                    <span>{{$t('botHeathlyDrawer.answer')}}</span>
                                </el-tooltip>
                                <!-- <span>答案组件多样性</span> -->
                                <!-- <span class="tips">(丰富答案组件多样性有利于提高咨询体验)</span> -->
                            </div>
                            <div class="used-components">{{$t('botHeathlyDrawer.usedCom')}}</div>
                        </div>
                        <div class="list-content">
                        <div class="item" v-for="(v,i) in componentslist" :key="i">
                                <div class="answer-item">
                                    <img v-if="v.type === 'text'" src="../../../assets/images/text.png" alt="">
                                    <img v-if="v.type === 'click-choose'" src="../../../assets/images/click-choose.png" alt="">
                                    <img v-if="v.type === 'img'" src="../../../assets/images/img.png" alt="">
                                    <img v-if="v.type === 'video'" src="../../../assets/images/video.png" alt="">
                                    <img v-if="v.type === 'editor'" src="../../../assets/images/editor.png" alt="">
                                    <span>{{v.text}}</span>
                                </div>
                                <div class="used-components">{{v.num}}</div>
                        </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="intent-detail-content">
                    <div class="intent-standard-tips">{{$t('botHeathlyDrawer.intentStandardTips')}}</div>
                    <div class="senior-filter-header-tag" v-if="seniorFilterList.length > 0 && isShowSenior">
                        <div class="showTag">
                            <div v-for="(tag,index) in seniorFilterList" :key="index">
                               <template v-if="tag.valueText !== '全部'">
                                    <el-tag
                                        closable
                                        :disable-transitions="false"
                                        @close="handleCloseTag(tag,index)"
                                        style="margin-right: 8px;"
                                        size="small">
                                        <span>{{tag.label}}:{{tag.valueText}}</span>
                                    </el-tag>
                               </template>
                            </div>
                            <el-tooltip class="item" effect="dark" :content="$t('botHeathlyDrawer.clearAll')" placement="top-start">
                                <span class="delete-all" @click="deleteAllTag">
                                    <i class="iconfont guoran-a-16_01"></i>
                                </span>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="intent-table">
                        <div class="list-header">
                            <div  style="width:175px">{{$t('botHeathlyDrawer.intent')}}</div>
                            <div  style="width:40px">{{$t('botHeathlyDrawer.question')}}</div>
                            <div  style="width:40px">{{$t('botHeathlyDrawer.rule')}}</div>
                            <div  style="width:60px">{{$t('botHeathlyDrawer.superIntent')}}</div>
                            <div  style="width:60px">{{$t('botHeathlyDrawer.dialogueProcess')}}</div>
                            <div  style="width:20px">{{$t('botHeathlyDrawer.meetingStandards')}}</div>
                        </div>
                        <div  :class="['list-content list-content-detail',intentList.length > 0 ? '' : 'no-data',seniorFilterList.length > 0 ? 'seniorFilterHeight' : '']">
                            <template v-if="intentList.length > 0">
                                <div class="item" v-for="(item) in intentList" :key="item.intentId" @click.stop="goLocation(item)">
                                     <el-tooltip effect="dark" :content="$t('botHeathlyDrawer.clearAll')" placement="left-start" :open-delay="1000">
                                            <div slot="content">{{item.intentName}}<br/>{{item.skillName}}/{{item.typeName}}</div>
                                            <div class="intent-name" style="width:175px">
                                                <div class="name one-column-ellipsis">{{item.intentName}}</div>
                                                <div class="intent-classifi  one-column-ellipsis" v-if="item.skillName && item.typeName">{{item.skillName}}/{{item.typeName}}</div>
                                            </div>
                                        </el-tooltip>
                                    
                                    <div class="question-num" style="width:40px">{{item.question}}</div>
                                    <div class="rule-num" style="width:40px">{{item.rule}}</div>
                                    <div style="width:60px" class="super-status">
                                        <div :class="[item.superIntent ? 'open' : 'close']">{{item.superIntent ? $t('botHeathlyDrawer.opened') : $t('botHeathlyDrawer.closed')}}</div>
                                    </div>
                                    <div style="width:60px" class="ask-status">
                                        <div :class="[item.hasProcess ? 'normal' : 'warn']">{{item.hasProcess ? $t('botHeathlyDrawer.normal') : $t('botHeathlyDrawer.lackOfProcess')}}</div>
                                    </div>
                                    <div class="is-standard" style="width:20px">
                                        <i class="el-icon-circle-close" v-if="!item.upToStandard"></i>
                                        <i class="el-icon-circle-check" v-else></i>
                                    </div>
                                </div>
                                <div class="look-more">
                                    <div v-if="loadStatus === 'loading'"><i class="el-icon-loading"></i>{{$t('botHeathlyDrawer.loading')}}...</div>
                                    <div v-else-if="loadStatus === 'noMore'">-- {{$t('botHeathlyDrawer.end')}} --</div>
                                </div>
                            </template>
                           <div v-else class="no-data-box">
                                <img style="width:300px;height:200px" src="../../../assets/images/emptyimg.png" alt="">
                                <div>{{$t('common.empty')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        <!-- </div> -->
        <popup @closeEvent="closeEvent" v-if="seniorFilterVisible" class="super-search">
            <div slot="popup-name">{{$t('botHeathlyDrawer.screen')}}</div>
                <div slot="popup-tip">{{$t('botHeathlyDrawer.screenTip')}}</div>
            <div slot="popup-con">
                <div class="senior-filter">
                    <div class="senior-filter-item">
                        <div class="label">{{$t('botHeathlyDrawer.status')}}</div>
                        <el-radio-group v-model="form.intentStatus">
                            <el-radio :label="1">{{$t('botHeathlyDrawer.all')}}</el-radio>
                            <el-radio :label="2">{{$t('botHeathlyDrawer.meetingStandards')}}</el-radio>
                            <el-radio :label="3">{{$t('botHeathlyDrawer.notMeetingStandards')}}</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="senior-filter-item">
                        <div class="label">{{$t('botHeathlyDrawer.question')}}</div>
                        <el-radio-group v-model="form.question">
                            <el-radio :label="1">{{$t('botHeathlyDrawer.all')}}</el-radio>
                            <el-radio :label="2">≥20{{$t('botHeathlyDrawer.strip')}}</el-radio>
                            <el-radio :label="3"> ＜20{{$t('botHeathlyDrawer.strip')}}</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="senior-filter-item">
                        <div class="label">{{$t('botHeathlyDrawer.rule')}}</div>
                        <el-radio-group v-model="form.rule">
                            <el-radio :label="1">{{$t('botHeathlyDrawer.all')}}</el-radio>
                            <el-radio :label="2">{{$t('botHeathlyDrawer.used')}}</el-radio>
                            <el-radio :label="3">{{$t('botHeathlyDrawer.notUsed')}}</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="senior-filter-item">
                        <div class="label">{{$t('botHeathlyDrawer.superIntent')}}</div>
                        <el-radio-group v-model="form.superIntent">
                            <el-radio :label="1">{{$t('botHeathlyDrawer.all')}}</el-radio>
                            <el-radio :label="2">{{$t('botHeathlyDrawer.opened')}}</el-radio>
                            <el-radio :label="3">{{$t('botHeathlyDrawer.notEnabled')}}</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="senior-filter-item">
                        <div class="label">{{$t('botHeathlyDrawer.dialogueProcess')}}</div>
                        <el-radio-group v-model="form.process">
                            <el-radio :label="1">{{$t('botHeathlyDrawer.all')}}</el-radio>
                            <el-radio :label="2">{{$t('botHeathlyDrawer.normal')}}</el-radio>
                            <el-radio :label="3">{{$t('botHeathlyDrawer.lackOfProcess')}}</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="senior-filter-item">
                        <div class="label">{{$t('botHeathlyDrawer.intentType')}}</div>
                        <el-radio-group v-model="form.intentType">
                            <el-radio :label="1">{{$t('botHeathlyDrawer.all')}}</el-radio>
                            <el-radio :label="2">{{$t('botHeathlyDrawer.buildOneself')}}</el-radio>
                            <el-radio :label="3">{{$t('botHeathlyDrawer.official')}}</el-radio>
                            <el-radio :label="4" class="edit-config">{{$t('botHeathlyDrawer.noEdit')}}</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button class="confirm-btn" type="primary" @click="saveSeniorFilter" round>{{$t('common.save')}}</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import healthyScore from './healthyScore.vue';
import Popup from '../../../components/popup.vue';
export default {
    data(){
        return{
            tableData:[],
            componentslist:[],
            drawerType:"heathly",
            intentList:[],
            seniorFilterVisible:false,
            form:{
                intentStatus:1,
                question:1,
                rule:1,
                superIntent:1,
                process:1,
                intentType:1
            },
            seniorFilterList:[],
            totalScore:0, // 总分
            loading:false,
            isChecking:false, // 是否正在检测
            SeniorFilterParams:{},
            loadStatus:"loading",
            showWebHook:false,
            showWorker:false,
            showDocument:false,
            progress:0,
            exportBtnLoading:false,
            getBotProfilePhoto:'',
            activeBot: "",
            botList: [],
            disabled:false,
            total:0,
            botName:'',
            refApiKey:'',
            botId:null,
            apiKey:"",
            updateIntentDetailId:'',
            updateIntentDetailBotId:'',
            isShowSenior:false,
            isScrolls:false
        }
    },
    components:{healthyScore,Popup},
    props:{
        botHeathlyDrawerVisible:{
            type:Boolean,
            default:false
        },
        // apiKey:{
        //     type:String,
        //     default:""
        // },
        // botId:{
        //     type:Number,
        //     default:null
        // }
    },
    watch:{
        botHeathlyDrawerVisible(n){
            if(n){
                this.drawerType = 'heathly';

                this.getBotList();
            }
        },
        drawerType(n){
            if(n === 'heathly'){
                document.querySelector('.list-content-detail').scrollTop = 0;
                this.resetData();
                
            } else {
               if(n === 'edit'){

               } else {
                    this.SeniorFilterParams = {
                        apiKey: this.apiKey,
                        pageNo: 1,
                        pageSize: 30,
                        intentStatus:1,
                        question:1,
                        rule:1,
                        superIntent:1,
                        process:1,
                        intentType:1
                    }
                      this.getDetailList('isStart');
               }
                this.disabled = true;
                this.isScrolls = false;
                this.intentList = [];
                this.SeniorFilterParams.pageNo = 1;
                this.judgeScrolls();
            }
        },
    },
    mounted(){
        this.$eventBus.$on("bot-heathly-visible", (visible) => {
            if(visible.flag){
                if(this.drawerType === 'detail'){
                    if(visible.type === 'updateIntentList'){
                        this.SeniorFilterParams.pageNo = 1;
                        this.seniorFilterList = [];
                        this.SeniorFilterParams = {
                            apiKey: this.apiKey,
                            pageNo: 1,
                            pageSize: 30,
                            intentStatus:1,
                            question:1,
                            rule:1,
                            superIntent:1,
                            process:1,
                            intentType:1
                        }
                        this.intentList = []
                        this.getDetailList('isStart');
                    } else  if(visible.type === 'updateIntentSkill'){
                        let updateIntentSkillList = JSON.parse(sessionStorage.getItem('differentSkill')) || [];
                        this.intentList.forEach((item,index) => {
                            updateIntentSkillList.forEach((v,i) => {
                                if(v.id == item.skillId){
                                    item.skillName = v.name;
                                }
                            })
                        })
                        sessionStorage.removeItem('differentSkill')
                    } else if (visible.type === 'updateIntentClass'){
                        let updateIntentClassList = JSON.parse(sessionStorage.getItem('differentClass')) || [];
                        this.intentList.forEach((item,index) => {
                            updateIntentClassList.forEach((v,i) => {
                                if(v.id == item.typeId){
                                    item.typeName = v.name;
                                }
                            })
                        }) 
                        sessionStorage.removeItem('updateIntentClass') 
                    } else if (visible.type === 'updateIntentDetail'){
                        console.log('----------');
                        this.updateIntentDetailId = visible.intentId;
                        this.updateIntentDetailBotId = visible.botId || ''
                        this.getDetailList('updateIntentDetail');
                    }  
                } else {
                    if (visible.type === 'nowOptimize'){
                        
                        this.selectBot(visible.botId)
                    }
                }
            }
        });
    },
    methods:{
        selectBot(val) {
            console.log(val);
            this.activeBot = val;
            this.botList.forEach(item => {
                if(val === item.id){
                    this.apiKey = item.apiKey;
                    this.getBotProfilePhoto = item.botProfilePhoto;
                    this.botName = item.name;
                    this.refApiKey = item.refApiKey
                }
            })
            this.botId = val;
            this.getAllData(val);
        },
        // 获取机器人列表
        getBotList() {
            this.FetchGet(this.requestUrl.bot.getBotList, {
                page: 1,
                size: 100,
            }).then((res) => {
                if (res.code === "0") {
                    this.botList = res.data.list;
                    if (this.botList.length > 0) {
                        this.activeBot =  this.botId = Number(this.$route.query.id);
                        this.botList.forEach(item => {
                            if(this.activeBot === item.id){
                                this.apiKey = item.apiKey;
                                this.getBotProfilePhoto = item.botProfilePhoto;
                                this.botName = item.name;
                                this.refApiKey = item.refApiKey
                            }
                        })
                    }
                    this.resetData();
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        resetData(){
            this.disabled = false;
            this.isChecking = false;
            // 1 试用 2 专业 3 企业
            this.showWebHook = localStorage.getItem('_bot_version') == 3 ||  localStorage.getItem('_bot_version') == 1 ? true : false;
            let rolePrivilegesList = JSON.parse(localStorage.getItem("rolePrivileges")) || [];
            this.showWorker = rolePrivilegesList.indexOf('PORTAL_ENTRANCE_WORKORDER') !== -1 ? true : false;
            this.showDocument = this.RP_Visible("DOCUMENT_EXTRACTION");
            this.seniorFilterList = [];
            this.getAllData(this.botId);
        },
        // 健康度初始化时 , 获取所有检测数据
        getAllData(botId){
            this.FetchGet(this.requestUrl.botHealthCheck.selectBotLastHealthCheck+'?botId='+botId ).then(res => {
                if (res.code === "0") {
                    let data = res.data.lastCheckDetails && res.data.lastCheckDetails ? res.data.lastCheckDetails : {}
                    this.totalScore = data.totalScore || 0;
                    this.getCheckData(data,'firstLoad');
                    this.answerComponentsData(data,'firstLoad');
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 获取检测项数据
        getCheckData(data,type){
            this.tableData = [{
                type:'score',
                name:this.$t('botHeathlyDrawer.intentTotal'),
                beforeTotal:data.selfBuiltTotal || 0,
                afterTotal:200,
                score:data.selfBuiltScore || 0,
                showChecked:data.selfBuiltScore && data.selfBuiltScore < 10 ? false : true,
                btnText:this.$t('botHeathlyDrawer.add')
            },{
                type:'score',
                name:this.$t('botHeathlyDrawer.noQualify'),
                beforeTotal:data.noStandardIntentTotal || 0,
                afterTotal:0,
                score:data.noStandardIntentScore || 0,
                showChecked:data.noStandardIntentScore && data.noStandardIntentScore < 10 ? false : true,
                btnText:this.$t('botHeathlyDrawer.optimization')
            },{
                type:'score',
                name:this.$t('botHeathlyDrawer.averageSimilarity'),
                beforeTotal:data.averageSimilarQuestionNum || 0,
                afterTotal:20,
                score:data.averageSimilarQuestionScore || 0,
                showChecked:data.averageSimilarQuestionScore && data.averageSimilarQuestionScore < 10 ? false : true,
                btnText:this.$t('botHeathlyDrawer.optimization')
            },{
                type:'score',
                name:this.$t('botHeathlyDrawer.answerCompleteness'),
                beforeTotal:data.answerCompleteLevelNum || 0,
                afterTotal:data.totalIntentAnswer || 0,
                score:data.answerCompleteLevelScore || 0,
                showChecked:data.answerCompleteLevelScore && data.answerCompleteLevelScore < 10 ? false : true,
                btnText:this.$t('botHeathlyDrawer.optimization')
            },{
                type:'score',
                name:this.$t('botHeathlyDrawer.unrecognized'),
                beforeTotal:data.noMarkedNonrecognitionNum || 0,
                afterTotal:50,
                score:data.noMarkedNonrecognitionScore || 0,
                showChecked:data.noMarkedNonrecognitionScore && data.noMarkedNonrecognitionScore < 10 ? false : true,
                btnText:this.$t('botHeathlyDrawer.tagging')
            },{
                type:'train',
                name:this.$t('botHeathlyDrawer.train'),
                beforeTotal:data.actualTrainingCount || 0,
                afterTotal:data.shouldTrainingCount || 0,
                score:data.trainingCycleScore || 0,
                showChecked:data.trainingCycleScore && data.trainingCycleScore > 0 ? true : false,
                btnText:this.$t('botHeathlyDrawer.practice')
            },{
                type:'score',
                name:this.$t('botHeathlyDrawer.keyWord'),
                beforeTotal:data.keyword || 0,
                afterTotal:10,
                score:data.keywordScore || 0,
                showChecked:data.keywordScore && data.keywordScore > 0 ? true : false,
                btnText:this.$t('botHeathlyDrawer.configure')
            },{
                type:'score',
                name:this.$t('botHeathlyDrawer.ruleTitle'),
                beforeTotal:data.useRuleIdentifyIntentAccounted || 0,
                afterTotal:'50%',
                score:data.useRuleIdentifyIntentScore || 0,
                showChecked:data.useRuleIdentifyIntentScore && data.useRuleIdentifyIntentScore > 0 ? true : false,
                btnText:this.$t('botHeathlyDrawer.optimization')
            },{
                type:'score',
                name:this.$t('botHeathlyDrawer.superIntentTitle'),
                beforeTotal:data.openSuperIntentAccounted || 0,
                afterTotal:'20%',
                score:data.openSuperIntentScore || 0,
                showChecked:data.openSuperIntentScore && data.openSuperIntentScore > 0 ? true : false,
                btnText:this.$t('botHeathlyDrawer.optimization')
            },{
                type:'score',
                name:this.$t('botHeathlyDrawer.editAnswer'),
                beforeTotal:data.updateOfficialIntentAnswerNum || 0,
                afterTotal:data.totalOfficialIntentNum || 0,
                score:data.updateOfficialIntentAnswerScore || 0,
                showChecked:data.updateOfficialIntentAnswerScore && data.updateOfficialIntentAnswerScore > 0 ? true : false,
                btnText:this.$t('botHeathlyDrawer.edit')
            },{
                type:'status',
                name:this.$t('botHeathlyDrawer.feedback'),
                beforeTotal: 0,
                afterTotal:0,
                score:data.answerFeedbackScore || 0,
                showChecked:data.answerFeedbacks && data.answerFeedbacks[0].open ? true : false,
                btnText:this.$t('botHeathlyDrawer.configure')
            },{
                type:'status',
                name:this.$t('botHeathlyDrawer.quickChooses'),
                beforeTotal: 0,
                afterTotal:0,
                score:data.quickChooseScore || 0,
                showChecked:data.quickChooses && data.quickChooses[0].open ? true : false,
                btnText:this.$t('botHeathlyDrawer.configure')
            },{
                type:'use',
                name:this.$t('botHeathlyDrawer.intelligentGuides'),
                beforeTotal: 0,
                afterTotal:0,
                score:data.intelligentGuideScore || 0,
                showChecked:data.intelligentGuides && data.intelligentGuides[0].configuration ? true : false,
                btnText:this.$t('botHeathlyDrawer.configure')
            },{
                type:'status',
                name:this.$t('botHeathlyDrawer.unrecognizedRecommendations'),
                beforeTotal: 0,
                afterTotal:0,
                score:data.unrecognizedRecommendationScore || 0,
                showChecked:data.unrecognizedRecommendations && data.unrecognizedRecommendations[0].open ? true : false,
                btnText:this.$t('botHeathlyDrawer.configure')
            },]
            if(this.showWebHook){
                this.tableData.push({
                    type:'use',
                    name:this.$t('botHeathlyDrawer.webHook'),
                    beforeTotal: 0,
                    afterTotal:0,
                    score:0,
                    showChecked:data.useWebHook || false,
                    btnText:this.$t('botHeathlyDrawer.use')
                })
            }
            if(this.showWorker){
                this.tableData.push({
                    type:'use',
                    name:this.$t('botHeathlyDrawer.order'),
                    beforeTotal: 0,
                    afterTotal:0,
                    score:0,
                    showChecked:data.useSmartWorkOrder || false,
                    btnText:this.$t('botHeathlyDrawer.use')
                })
            }
            if(this.showDocument){
                this.tableData.push({
                    type:'use',
                    name:this.$t('botHeathlyDrawer.doc'),
                    beforeTotal: 0,
                    afterTotal:0,
                    score:0,
                    showChecked:data.useDocumentExtracting || false,
                    btnText:this.$t('botHeathlyDrawer.use')
                })
            }
        },
        // 答案组件多样性数据
        answerComponentsData(data,type){
            this.componentslist = [{
                type:'text',
                text:"文本",
                num:data.textModule || 0,
            },{
                type:'click-choose',
                text:"点选",
                num:data.radioModule || 0,
            },{
                type:'img',
                text:"图片",
                num:data.picModule || 0,
            },{
                type:'video',
                text:"视频",
                num:data.videoModule || 0,
            },{
                type:'editor',
                text:"富文本",
                num:data.riceTextModule || 0,
            }]
            if(type && type === 'firstLoad'){
                this.loading = false
            }
        },
        handleClose(){
            this.drawerType = 'heathly'
            this.$emit('update:botHeathlyDrawerVisible',false)
            this.$eventBus.$emit("bot-heathly-visible", false);
            this.$eventBus.$emit("bot-heathly-visible", {
                flag:false,
            });
        },
        // 重新检测   待优化代码
        async reCheckAgain(){
            console.log(this.showDocument,'showDocument');
            console.log(this.showWorker,'showWorker');
            console.log(this.showWebHook,'showWebHook');
            this.isChecking = true;
            this.loading = true;
            this.progress = 0;
            let allLength = this.tableData.length;
            // 检查机器人设置是否开启答案反馈、快捷选择、不识别推荐、关键词
            // 里面包含答案组件多样性数据
            this.FetchGet(this.requestUrl.botHealthCheck.checkBotSetting+'?apiKey='+this.apiKey+'&botId='+this.botId ).then(async res => {
                if (res.code === "0") {
                    let data = res.data;
                    this.totalScore = data.totalScore;
                    this.tableData = [];
                    this.componentslist = [];
                    this.answerComponentsData(data)
                    if(!this.isChecking)return;
                    let documentRes = {};
                    let showWorkerRes = {};
                    let showWebHookRes = {};
                    // 查询使用文档抽取能力
                    documentRes =  await this.checkDocumentExtractings(this.showDocument);
                    //  使用智能工单能力
                    showWorkerRes = await this.checkUseWebHookAndSmartWorkOrders();
                    //  使用WebHook能力
                    // showWebHookRes = await this.checkUseWebHookAndSmartWorkOrders(this.showWebHook,'WebHook');
                   if(documentRes.flag){
                        if(this.showDocument){
                            if(!this.isChecking)return;
                            this.tableData.push({
                                type:'use',
                                name:this.$t('botHeathlyDrawer.doc'),
                                beforeTotal: 0,
                                afterTotal:0,
                                score:0,
                                showChecked:documentRes.data.useDocumentExtracting,
                                btnText:this.$t('botHeathlyDrawer.use')
                            })
                            this.handlerProgress(allLength,this.$t('botHeathlyDrawer.doc'));
                            if(!this.isChecking)return;
                            // 接下来的操作
                            // this.handlerSurplusData(res.data,allLength)
                            if(showWorkerRes.flag){
                                this.handlerData(showWorkerRes,res,allLength);
                            }
                        } else {
                            if(!this.isChecking)return;
                            this.handlerData(showWorkerRes,res,allLength);
                        }
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 查询使用文档抽取能力
        async  checkDocumentExtractings(showDocument){
            let flag = false;
            let documentRess = {}
            await this.FetchGet(this.requestUrl.botHealthCheck.checkDocumentExtracting+'?apiKey='+this.apiKey).then(documentRes => {
                if (documentRes.code === "0") {
                    this.totalScore = documentRes.data.totalScore;
                    documentRess = documentRes;
                    // if(this.showWorker){
                        // setTimeout(() => {
                            // this.tableData.push({
                            //     type:'use',
                            //     name:"使用文档抽取能力",
                            //     beforeTotal: 0,
                            //     afterTotal:0,
                            //     score:0,
                            //     showChecked:documentRes.data.useDocumentExtracting,
                            //     btnText:"如何使用"
                            // })
                            flag = true
                        // }, 200);
                    // }  else {
                    //    flag = await true
                    // }
                    // console.log(flag);
                } else {
                    this.$message.error(documentRes.message);
                    flag = true;
                    // return flag
                }
            })
            return {
                flag:flag,
                data:documentRess.data || {}
            };
           
        },
        // 使用WebHook能力或智能工单能力
        async checkUseWebHookAndSmartWorkOrders(isShow,type){
            let flag = false;
            let useWorkerRess = {}
            await this.FetchGet(this.requestUrl.botHealthCheck.checkUseWebHookAndSmartWorkOrder+'?apiKey='+this.apiKey+"&botId="+this.botId+'&enterpriseEditionCompany='+this.showWebHook+'&haveWorkOrder='+this.showWorker).then(useWorkerRes => {
                if (useWorkerRes.code === "0") {
                    this.totalScore = useWorkerRes.data.totalScore;
                    useWorkerRess = useWorkerRes;
                    flag = true;
                } else {
                    flag = true;
                    this.$message.error(useWorkerRes.message);
                }
            })
            return {
                flag:flag,
                data:useWorkerRess.data || {}
            };
        },
        // 除了文档抽取能力/智能工单能力/WebHook能力之外的剩余数据
        handlerSurplusData(data,allLength){
            if(!this.isChecking)return;
            this.FetchGet(this.requestUrl.botHealthCheck.updateOfficialIntentAnswer+'?apiKey='+this.apiKey+"&botId="+this.botId).then(intentRes => {
                if (intentRes.code === "0") {
                    // clearImmediate(ResTime2)
                    if(!this.isChecking)return;
                    this.totalScore = intentRes.data.totalScore;
                    setTimeout(() =>  {
                        if(!this.isChecking)return;
                        this.tableData = [...[{
                            type:'status',
                            name:this.$t('botHeathlyDrawer.unrecognizedRecommendations'),
                            beforeTotal: 0,
                            afterTotal:0,
                            score:data.unrecognizedRecommendationScore,
                            showChecked:data.unrecognizedRecommendations && data.unrecognizedRecommendations[0].open ? true : false,
                            btnText:this.$t('botHeathlyDrawer.configure')
                        }],...this.tableData];  
                        this.handlerProgress(allLength,this.$t('botHeathlyDrawer.unrecognizedRecommendations'));
                        // clearImmediate(ResTime3)
                        if(!this.isChecking)return;
                        setTimeout(() => {
                            if(!this.isChecking)return;
                            this.tableData = [...[{
                                type:'use',
                                name:this.$t('botHeathlyDrawer.intelligentGuides'),
                                beforeTotal: 0,
                                afterTotal:0,
                                score:data.intelligentGuideScore,
                                showChecked:data.intelligentGuides && data.intelligentGuides[0].configuration ? true : false,
                                btnText:this.$t('botHeathlyDrawer.configure')
                            }],...this.tableData];
                            this.handlerProgress(allLength,this.$t('botHeathlyDrawer.intelligentGuides'));
                            let ResTime4 = setTimeout(() =>  {
                                if(!this.isChecking)return;
                                this.tableData = [...[{
                                    type:'status',
                                    name:this.$t('botHeathlyDrawer.quickChooses'),
                                    beforeTotal: 0,
                                    afterTotal:0,
                                    score:data.quickChooseScore || 0,
                                    showChecked:data.quickChooses && data.quickChooses[0].open ? true : false,
                                    btnText:this.$t('botHeathlyDrawer.configure')
                                }],...this.tableData];
                                this.handlerProgress(allLength,this.$t('botHeathlyDrawer.quickChooses'));
                                if(!this.isChecking)return;
                                // clearImmediate(ResTime4)
                                let ResTime5 = setTimeout(() =>  {
                                    if(!this.isChecking)return;
                                    this.tableData = [...[{
                                        type:'status',
                                        name:this.$t('botHeathlyDrawer.feedback'),
                                        beforeTotal: 0,
                                        afterTotal:0,
                                        score:data.answerFeedbackScore || 0,
                                        showChecked:data.answerFeedbacks && data.answerFeedbacks[0].open ? true : false,
                                        btnText:this.$t('botHeathlyDrawer.configure')
                                    }],...this.tableData];
                                    this.handlerProgress(allLength,this.$t('botHeathlyDrawer.feedback'));
                                    if(!this.isChecking)return;
                                    // clearImmediate(ResTime5)
                                    let intentResTime2 = setTimeout(() =>  {
                                        if(!this.isChecking)return;
                                        this.tableData = [...[{
                                        type:'score',
                                            name:this.$t('botHeathlyDrawer.editAnswer'),
                                            beforeTotal:intentRes.data.updateOfficialIntentAnswerNum || 0,
                                            afterTotal:intentRes.data.totalOfficialIntentNum || 0,
                                            score:intentRes.data.updateOfficialIntentAnswerScore || 0,
                                            showChecked:intentRes.data.updateOfficialIntentAnswerScore && intentRes.data.updateOfficialIntentAnswerScore > 0 ? true : false,
                                            btnText:this.$t('botHeathlyDrawer.edit')
                                        }],...this.tableData];
                                        this.handlerProgress(allLength,this.$t('botHeathlyDrawer.editAnswer'));
                                        // clearImmediate(intentResTime2)
                                        if(!this.isChecking)return;
                                        // 开启超级意图意图占比  使用规则识别意图占比
                                        this.FetchGet(this.requestUrl.botHealthCheck.checkBotUseRuleAndSuperIntent+'?apiKey='+this.apiKey+"&botId="+this.botId).then(checkBotUseRuleAndSuperIntentRes => {
                                            if (checkBotUseRuleAndSuperIntentRes.code === "0") {
                                                this.totalScore = checkBotUseRuleAndSuperIntentRes.data.totalScore;
                                                if(!this.isChecking)return;
                                                setTimeout(() =>  {
                                                    if(!this.isChecking)return;
                                                    this.tableData = [...[{
                                                        type:'score',
                                                        name:this.$t('botHeathlyDrawer.superIntentTitle'),
                                                        beforeTotal:checkBotUseRuleAndSuperIntentRes.data.openSuperIntentAccounted || 0,
                                                        afterTotal:'20%',
                                                        score:checkBotUseRuleAndSuperIntentRes.data.openSuperIntentScore || 0,
                                                        showChecked:checkBotUseRuleAndSuperIntentRes.data.openSuperIntentScore && checkBotUseRuleAndSuperIntentRes.data.openSuperIntentScore > 0 ? true : false,
                                                        btnText:this.$t('botHeathlyDrawer.optimization')
                                                    }],...this.tableData];
                                                    this.handlerProgress(allLength,this.$t('botHeathlyDrawer.superIntentTitle'));
                                                     if(!this.isChecking)return;
                                                    setTimeout(() =>  {
                                                        if(!this.isChecking)return;
                                                        this.tableData = [...[{
                                                            type:'score',
                                                            name:this.$t('botHeathlyDrawer.ruleTitle'),
                                                            beforeTotal:checkBotUseRuleAndSuperIntentRes.data.useRuleIdentifyIntentAccounted || 0,
                                                            afterTotal:'50%',
                                                            score:checkBotUseRuleAndSuperIntentRes.data.useRuleIdentifyIntentScore || 0,
                                                            showChecked:checkBotUseRuleAndSuperIntentRes.data.useRuleIdentifyIntentScore && checkBotUseRuleAndSuperIntentRes.data.useRuleIdentifyIntentScore > 0 ? true : false,
                                                            btnText:this.$t('botHeathlyDrawer.optimization')
                                                        }],...this.tableData];
                                                        this.handlerProgress(allLength,this.$t('botHeathlyDrawer.ruleTitle'));
                                                        // clearImmediate(checkBotUseRuleAndSuperIntentResTime)
                                                         if(!this.isChecking)return;
                                                        setTimeout(() =>  {
                                                            if(!this.isChecking)return;
                                                            this.tableData = [...[{
                                                                type:'score',
                                                                name:this.$t('botHeathlyDrawer.keyWord'),
                                                                beforeTotal:data.keyword || 0,
                                                                afterTotal:10,
                                                                score:data.keywordScore || 0,
                                                                showChecked:data.keywordScore && data.keywordScore > 0 ? true : false,
                                                                btnText:this.$t('botHeathlyDrawer.configure')
                                                            }],...this.tableData];
                                                            this.handlerProgress(allLength,this.$t('botHeathlyDrawer.keyWord'));
                                                            // clearImmediate(checkBotUseRuleAndSuperIntentResTime)
                                                            if(!this.isChecking)return;
                                                            // 机器学习训练周期
                                                            this.FetchGet(this.requestUrl.botHealthCheck.machineLearningTrain+'?apiKey='+this.apiKey + '&botId='+this.botId ).then(trainRes => {
                                                                if (trainRes.code === "0") {
                                                                    this.totalScore = trainRes.data.totalScore;
                                                                     if(!this.isChecking)return;
                                                                    setTimeout(() =>  {
                                                                        if(!this.isChecking)return;
                                                                        this.tableData = [
                                                                            ...[{                                              
                                                                                type:'train',
                                                                                name:this.$t('botHeathlyDrawer.train'),
                                                                                beforeTotal:trainRes.data.actualTrainingCount || 0,
                                                                                afterTotal:trainRes.data.shouldTrainingCount || 0,
                                                                                score:trainRes.data.trainingCycleScore || 0,
                                                                                showChecked:trainRes.data.trainingCycleScore && trainRes.data.trainingCycleScore > 0 ? true : false,
                                                                                btnText:this.$t('botHeathlyDrawer.practice')
                                                                            }]
                                                                        ,...this.tableData];
                                                                        this.handlerProgress(allLength,this.$t('botHeathlyDrawer.train'));
                                                                        if(!this.isChecking)return;
                                                                        // 未标注不识别问题数
                                                                        this.FetchGet(this.requestUrl.botHealthCheck.machineLearning+'?apiKey='+this.apiKey + '&botId='+this.botId ).then(learnRes => {
                                                                            if (learnRes.code === "0") {
                                                                                this.totalScore = learnRes.data.totalScore;
                                                                                 if(!this.isChecking)return;
                                                                                setTimeout(() =>  {
                                                                                    if(!this.isChecking)return;
                                                                                    this.tableData = [
                                                                                        ...[{                                              
                                                                                            type:'score',
                                                                                            name:this.$t('botHeathlyDrawer.unrecognized'),
                                                                                            beforeTotal:learnRes.data.noMarkedNonrecognitionNum || 0,
                                                                                            afterTotal:50,
                                                                                            score:learnRes.data.noMarkedNonrecognitionScore || 0,
                                                                                            showChecked:learnRes.data.noMarkedNonrecognitionScore && learnRes.data.noMarkedNonrecognitionScore < 10 ? false : true,
                                                                                            btnText:this.$t('botHeathlyDrawer.tagging')
                                                                                        }]
                                                                                    ,...this.tableData];
                                                                                    this.handlerProgress(allLength,this.$t('botHeathlyDrawer.unrecognized'));
                                                                                    if(!this.isChecking)return;
                                                                                    // 答案完整度
                                                                                this.FetchGet(this.requestUrl.botHealthCheck.answerFull+'?apiKey='+this.apiKey+"&botId="+this.botId).then(answerFullRes => {
                                                                                    if (answerFullRes.code === "0") {
                                                                                        this.totalScore = answerFullRes.data.totalScore;
                                                                                         if(!this.isChecking)return;
                                                                                        setTimeout(() =>  {
                                                                                            if(!this.isChecking)return;
                                                                                            this.tableData = [
                                                                                                ...[{                                              
                                                                                                    type:'score',
                                                                                                    name:this.$t('botHeathlyDrawer.answerCompleteness'),
                                                                                                    beforeTotal:answerFullRes.data.answerCompleteLevelNum || 0,
                                                                                                    afterTotal:answerFullRes.data.totalIntentAnswer || 0,
                                                                                                    score:answerFullRes.data.answerCompleteLevelScore || 0,
                                                                                                    showChecked:answerFullRes.data.answerCompleteLevelScore && answerFullRes.data.answerCompleteLevelScore < 10 ? false : true,
                                                                                                    btnText:this.$t('botHeathlyDrawer.optimization')
                                                                                                }]
                                                                                            ,...this.tableData];
                                                                                            this.handlerProgress(allLength,this.$t('botHeathlyDrawer.answerCompleteness'));
                                                                                            if(!this.isChecking)return;
                                                                                            // 平均相似问法数
                                                                                            this.FetchGet(this.requestUrl.botHealthCheck.similarityQuestions+'?apiKey='+this.apiKey+"&botId="+this.botId).then(similarityQuestionsRes => {
                                                                                                if (similarityQuestionsRes.code === "0") {
                                                                                                    this.totalScore = similarityQuestionsRes.data.totalScore;
                                                                                                     if(!this.isChecking)return;
                                                                                                    setTimeout(() => {
                                                                                                        if(!this.isChecking)return;
                                                                                                        this.tableData = [
                                                                                                            ...[{                                              
                                                                                                            type:'score',
                                                                                                                name:this.$t('botHeathlyDrawer.averageSimilarity'),
                                                                                                                beforeTotal:similarityQuestionsRes.data.averageSimilarQuestionNum || 0,
                                                                                                                afterTotal:20,
                                                                                                                score:similarityQuestionsRes.data.averageSimilarQuestionScore || 0,
                                                                                                                showChecked:similarityQuestionsRes.data.averageSimilarQuestionScore && similarityQuestionsRes.data.averageSimilarQuestionScore < 10 ? false : true,
                                                                                                                btnText:this.$t('botHeathlyDrawer.optimization')
                                                                                                            }]
                                                                                                        ,...this.tableData];
                                                                                                        this.handlerProgress(allLength,this.$t('botHeathlyDrawer.averageSimilarity'));
                                                                                                        if(!this.isChecking)return;
                                                                                                        // 查询未达标意图数
                                                                                                        this.FetchGet(this.requestUrl.botHealthCheck.notQualified+'?apiKey='+this.apiKey+"&botId="+this.botId).then(notQualified => {
                                                                                                            this.totalScore = notQualified.data.totalScore;
                                                                                                            if (notQualified.code === "0") {
                                                                                                                 if(!this.isChecking)return;
                                                                                                                setTimeout(() => {
                                                                                                                    if(!this.isChecking)return;
                                                                                                                    this.tableData = [
                                                                                                                        ...[{                                              
                                                                                                                        type:'score',
                                                                                                                            name:this.$t('botHeathlyDrawer.noQualify'),
                                                                                                                            beforeTotal:notQualified.data.noStandardIntentTotal || 0,
                                                                                                                            afterTotal:0,
                                                                                                                            score:notQualified.data.noStandardIntentScore || 0,
                                                                                                                            showChecked:notQualified.data.noStandardIntentScore && notQualified.data.noStandardIntentScore  < 10 ? false : true,
                                                                                                                            btnText:this.$t('botHeathlyDrawer.optimization')
                                                                                                                        }]
                                                                                                                    ,...this.tableData];
                                                                                                                    this.handlerProgress(allLength,this.$t('botHeathlyDrawer.noQualify'));
                                                                                                                    if(!this.isChecking)return;
                                                                                                                    // 自建意图总数
                                                                                                                    this.FetchGet(this.requestUrl.botHealthCheck.querySelfBuiltIntentNum+'?apiKey='+this.apiKey+"&botId="+this.botId).then(querySelfBuiltIntentNumRes => {
                                                                                                                        if (querySelfBuiltIntentNumRes.code === "0") {
                                                                                                                            this.totalScore = querySelfBuiltIntentNumRes.data.totalScore;
                                                                                                                             if(!this.isChecking)return;
                                                                                                                            setTimeout(() => {
                                                                                                                                if(!this.isChecking)return;
                                                                                                                                this.tableData = [
                                                                                                                                    ...[{                                              
                                                                                                                                        type:'score',
                                                                                                                                        name:this.$t('botHeathlyDrawer.intentTotal'),
                                                                                                                                        beforeTotal:querySelfBuiltIntentNumRes.data.selfBuiltTotal || 0,
                                                                                                                                        afterTotal:200,
                                                                                                                                        score:querySelfBuiltIntentNumRes.data.selfBuiltScore || 0,
                                                                                                                                        showChecked:querySelfBuiltIntentNumRes.data.selfBuiltScore && querySelfBuiltIntentNumRes.data.selfBuiltScore < 10 ? false : true,
                                                                                                                                        btnText:this.$t('botHeathlyDrawer.add')
                                                                                                                                    }]
                                                                                                                                ,...this.tableData];
                                                                                                                                this.handlerProgress(allLength,this.$t('botHeathlyDrawer.intentTotal'));
                                                                                                                                setTimeout(() => {
                                                                                                                                    this.isChecking = false;
                                                                                                                                }, 200);
                                                                                                                            }, 200);
                                                                                                                        } else {
                                                                                                                            this.$message.error(querySelfBuiltIntentNumRes.message);
                                                                                                                        }
                                                                                                                    });
                                                                                                                }, 200);
                                                                                                            } else {
                                                                                                                this.$message.error(notQualified.message);
                                                                                                            }
                                                                                                        });
                                                                                                    }, 200);
                                                                                                } else {
                                                                                                    this.$message.error(similarityQuestionsRes.message);
                                                                                                }
                                                                                            });
                                                                                        }, 200);
                                                                                        
                                                                                    } else {
                                                                                        this.$message.error(answerFullRes.message);
                                                                                    }
                                                                                });
                                                                                
                                                                                }, 200);
                                                                            } else {
                                                                                this.$message.error(learnRes.message);
                                                                            }
                                                                        });
                                                                    }, 200);
                                                                } else {
                                                                    this.$message.error(trainRes.message);
                                                                }
                                                            });
                                                        }, 200);
                                                    }, 200);
                                                }, 200);
                                            } else {
                                                this.$message.error(checkBotUseRuleAndSuperIntentRes.message);
                                            }
                                        });
                                        
                                    }, 200);
                                }, 200);
                            }, 200);
                        }, 200);
                    }, 200);
                } else {
                    this.$message.error(intentRes.message);
                }
            });
        },
        handlerProgress(allLength,text){
            let nowLength = this.tableData.length;
            this.progress = (nowLength/allLength).toFixed(2)*100;
        },
        showWorkerData(showWorkerRes,res,allLength){
            // let timeNum = this.showDocument ? 400 : 200;
            // setTimeout(() => {
            //     this.tableData = [...[{
            //         type:'use',
            //         name:"流程中使用智能工单能力",
            //         beforeTotal: 0,
            //         afterTotal:0,
            //         score:0,
            //         showChecked:showWorkerRes.data.useSmartWorkOrder,
            //         btnText:"如何使用"
            //     }],...this.tableData]
            //     this.handlerProgress(allLength,'流程中使用智能工单能力');
            // }, timeNum);
        },
        handlerData(showWorkerRes,res,allLength){
            if(this.showWorker){
                if(this.isChecking){
                    let timeNum = this.showDocument ? 400 : 200;
                    setTimeout(() => {
                        this.tableData = [...[{
                            type:'use',
                            name:this.$t('botHeathlyDrawer.order'),
                            beforeTotal: 0,
                            afterTotal:0,
                            score:0,
                            showChecked:showWorkerRes.data.useSmartWorkOrder,
                            btnText:this.$t('botHeathlyDrawer.use')
                        }],...this.tableData]
                        this.handlerProgress(allLength,this.$t('botHeathlyDrawer.order'));
                        if(!this.isChecking)return;
                        if(this.showWebHook){
                            let timeNum = 0;
                            if(this.showDocument){
                                timeNum+=200;
                            }
                            if(this.showWorker){
                                timeNum+=200;
                            }
                            setTimeout(() => {
                                if(!this.isChecking)return;
                                this.tableData = [...[{
                                    type:'use',
                                    name:this.$t('botHeathlyDrawer.webHook'),
                                    beforeTotal: 0,
                                    afterTotal:0,
                                    score:0,
                                    showChecked:showWorkerRes.data.useWebHook,
                                    btnText:this.$t('botHeathlyDrawer.use')
                                }],...this.tableData];
                                this.handlerProgress(allLength,this.$t('botHeathlyDrawer.webHook'));
                                // 接下来的操作
                                this.handlerSurplusData(res.data,allLength)
                            }, timeNum);
                        } else {
                            // 接下来的操作
                            this.handlerSurplusData(res.data,allLength)
                        }
                    }, timeNum);
                    // this.showWorkerData(showWorkerRes,res,allLength);
                   
                };
            } else{
                if(!this.isChecking)return;
                if(this.showWebHook){
                    let timeNum = 200;
                    if(this.showDocument){
                        timeNum+=200;
                    }
                    if(this.showWorker){
                        timeNum+=200;
                    }
                    setTimeout(() => {
                        if(!this.isChecking)return;
                        this.tableData = [...[{
                            type:'use',
                            name:this.$t('botHeathlyDrawer.webHook'),
                            beforeTotal: 0,
                            afterTotal:0,
                            score:0,
                            showChecked:showWorkerRes.data.useWebHook,
                            btnText:this.$t('botHeathlyDrawer.use')
                        }],...this.tableData];
                        this.handlerProgress(allLength,this.$t('botHeathlyDrawer.webHook'));
                        // 接下来的操作
                        this.handlerSurplusData(res.data,allLength)
                    }, timeNum);
                }else {
                    // 接下来的操作
                    this.handlerSurplusData(res.data,allLength)
                }
            }
        },
        // 暂停检测
        stopCheck(){
            this.FetchGet(this.requestUrl.botHealthCheck.stopCheck+'?apiKey='+this.apiKey).then(res => {
                if (res.code === "0") {
                    this.isChecking = false;
                    this.$message.closeAll();
                    this.$message({
                        message: '暂停检测成功',
                        type: 'success',
                        duration:1000
                    })
                    this.getAllData(this.botId);
                } else {
                    this.$message.error(res.message);
                }
            }); 
        },
         // 点击按钮的操作
        btnOperation(item,index){
            let flag = true;
            console.log(item,item.name === '自建意图总数');
            let routerName = '',tabIndex='',botSettingTab='', routeData=null,query={}
            if(item.name === '自建意图总数'){
                if(this.RP_Visible('OPEN_SKILL_INTENT_ADD')){
                    this.FetchGet(this.requestUrl.bot.selectLimitMsg + '?uid=' + localStorage.getItem("_uid")).then((res) => {
                        if (res.code === "0") {
                            if(res.data.canCreateIntent){
                                flag = true;
                                this.$router.push({
                                    name: 'botIntent',
                                    query:{
                                        id: this.botId, 
                                        name:this.botName,
                                        refApiKey:this.refApiKey,
                                        from:'cursor',
                                        tabIndex:"skill" 
                                    }
                                })
                                setTimeout(() => {
                                    this.$eventBus.$emit("bot-heathly-visible", {
                                        flag:true,
                                        type:'addIntent',
                                    });
                                }, 200);
                            } else {
                                flag = false;
                                this.$message.warning('暂无新增意图权限')
                            }
                        }
                    });
                } else {
                    flag = false;
                    this.$message.warning('暂无新增意图权限')
                }
            } else if (item.name === '机器学习训练周期' || item.name === '未标注不识别问题数'){
               if(this.RP_Visible('OPEN_ML') && this.RP_Visible('OPEN_ML_VIEW')){
                   flag = true;
                   routerName = 'trainList';
                    query = {
                        isHeathly:1,
                        botId:this.botId
                    }
               } else {
                   flag = false;
                   this.$message.warning('暂无访问权限')
               }
                
            } else if (item.name === '关键词个数' || item.name === '开启全局答案反馈' || item.name === '开启全局快捷选择' || item.name === '开启不识别推荐'){
                if(this.RP_Visible('OPEN_CONFIG') && this.RP_Visible('OPEN_CONFIG_VIEW')){
                    flag = true;
                    routerName = 'botIntent'
                    tabIndex='bot-setting'
                    if(item.name === '关键词个数' ){
                        botSettingTab='KEYWORD_REC';
                    } else if(item.name === '开启全局答案反馈' ){
                        botSettingTab='ANSWER_FEEDBACK';
                    } else if(item.name === '开启全局快捷选择' ){
                        botSettingTab='FAST_SELECT';
                    } else if(item.name === '开启不识别推荐' ){
                        botSettingTab='bot-setting-noDescernIntentON';
                    }
                    query  = {
                        id: this.botId, 
                        name:this.botName,
                        refApiKey:this.refApiKey,
                        from:'cursor',
                        botSettingTab:botSettingTab
                    } 
                } else {
                    flag = false;
                    this.$message.warning('暂无访问权限')
                }
            } else if(item.name === '欢迎语配置智能引导'){
                if(this.RP_Visible('OPEN_PROCESS') && this.RP_Visible('OPEN_PROCESS_VIEW')){
                    flag = true;
                    routerName = 'chatFlow';
                    query = {
                        id: this.botId,
                        name:this.botName,
                        isHeathly:1, // 
                    } 
                } else {
                    flag = false;
                    this.$message.warning('暂无访问权限')
                }
                
            } else if(item.name === '流程中使用智能工单能力' || item.name === '使用文档抽取能力' || item.name === '流程中使用WebHook能力'){
                flag = true;
                let helpSrc = ''
                if(item.name === '流程中使用智能工单能力'){
                    helpSrc = 'https://showdoc.askbot.cn/web/#/readonly/5?page_id=56'
                } else if(item.name === '使用文档抽取能力'){
                    helpSrc = 'https://showdoc.askbot.cn/web/#/readonly/5?page_id=55'
                } else if(item.name === '流程中使用WebHook能力'){
                    helpSrc = 'https://showdoc.askbot.cn/web/#/readonly/5?page_id=38'
                }
                console.log(helpSrc,'helpSrc');
                sessionStorage.setItem('helpSrc',helpSrc)
                let routeData = this.$router.resolve({
                    name: 'help', 
                });
                window.open(
                    window.location.href.split("/#/")[0] +
                        "/#" +
                        routeData.resolved.fullPath,
                    "_blank"
                );
            } else {
                // 切换到意图详情
                flag = true;
                this.drawerType = 'detail';
            }
            if(item.btnText === '优化' || item.btnText === '修改'){
                flag = true;
                 // 切换到意图详情
                this.form = {
                    intentStatus:1,
                    question:1,
                    rule:1,
                    superIntent:1,
                    process:1,
                    intentType:1
                }
                if(item.name === '未达标意图数'){
                    this.form.intentStatus = 3;
                } else if(item.name === '平均相似问法数'){
                    this.form.question = 3;
                    this.form.intentType = 2;
                } else if(item.name === '答案完整度'){
                    this.form.process = 3;
                } else if(item.name === '使用规则识别意图占比'){
                    this.form.rule = 3;
                    this.form.intentType = 2;
                } else if(item.name === '开启超级意图意图占比'){
                    this.form.superIntent = 3;
                    this.form.intentType = 2;
                } else if(item.name === '修改官方意图的原有答案'){
                    this.form.intentType = 4;
                } 
                this.drawerType = 'edit';
                this.saveSeniorFilter()
            }else {
                if(item.name !== '自建意图总数' && item.btnText !== '如何使用'){
                    if(!flag) return;
                    this.$router.push({
                        name: routerName,
                        query: query,
                    });
                } else {

                }
            }
        },
        // 点击意图名称,定位到对应意图
        goLocation(item){
            if(!item.userCanSeeSkill){
                this.$message.warning('暂无意图访问权限')
            } else {
                sessionStorage.setItem("_activeSkill", item.skillId);
                sessionStorage.setItem("_activeClass", item.typeId);
                sessionStorage.setItem("openIntentWeb", 1);
                sessionStorage.setItem("openIntentId", item.intentId);
                this.$router.push({
                    name: 'botIntent',
                    query:{
                        id: this.botId, 
                        name:this.botName,
                        refApiKey:this.refApiKey,
                        from:'cursor',
                        tabIndex:"skill" 
                    }
                })
                this.$eventBus.$emit("bot-heathly-visible", {
                    flag:true,
                    type:'goLocationIntent',
                    route:'skill'
                });
            }
        },
        // 导出
        exportData(){
            this.exportBtnLoading = true;
            this.FetchGet(this.requestUrl.botHealthCheck.botHealthCheckResultExport+"?botId="+this.botId+'&enterpriseEditionCompany='+this.showWebHook+'&haveWorkOrder='+this.showWorker+'&canUseDocumentExtracting='+this.showDocument).then(res => {
                if (res.code === '0'){
                   setTimeout(() => {
                        window.location.href = res.data;
                        this.$message({
                            message:'导出成功',
                            type:'success',
                            duration:1000
                        })
                        this.exportBtnLoading = false;
                   },1000)
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        // 返回健康度
        backHeathly(){
            this.isShowSenior = false;
            this.seniorFilterList = [];
            setTimeout(() => {
                this.drawerType = 'heathly';
            },10)
        },
        // 打开高级筛选
        openSeniorFilter(){
            let obj = JSON.parse(JSON.stringify(this.SeniorFilterParams));
            delete obj.pageNo;
            delete obj.pageSize;
            delete obj.apiKey;
            this.form = obj;
            this.seniorFilterVisible = true;
        },
        // 关闭高级筛选
        closeEvent(){
            this.seniorFilterVisible = false;
        },
        // 保存高级筛选条件
        saveSeniorFilter(){
            this.seniorFilterList = [];
            console.log(this.form);
            for(let key in this.form){
                let label = '',valueText = '';
                if(this.form[key] === 1){
                    valueText = '全部';
                }
                if(key === 'intentStatus'){
                    label = '状态';
                    if(this.form[key] === 2){
                        valueText = '达标';
                    } else if(this.form[key] === 3){
                        valueText = '未达标';
                    }
                } else if (key === 'question'){
                    label = '问法';
                    if(this.form[key] === 2){
                        valueText = '≥20条';
                    } else if(this.form[key] === 3){
                        valueText = '＜20条';
                    }
                } else if (key === 'rule'){
                    label = '规则';
                    if(this.form[key] === 2){
                        valueText = '已使用';
                    } else if(this.form[key] === 3){
                        valueText = '未使用';
                    }
                } else if (key === 'superIntent'){
                    label = '超级意图';
                    if(this.form[key] === 2){
                        valueText = '已开启';
                    } else if(this.form[key] === 3){
                        valueText = '未开启';
                    }
                } else if (key === 'process'){
                    label = '对话流程';
                    if(this.form[key] === 2){
                        valueText = '正常';
                    } else if(this.form[key] === 3){
                        valueText = '缺流程';
                    }
                } else if (key === 'intentType'){
                    label = '意图类型';
                    if(this.form[key] === 2){
                        valueText = '自建';
                    } else if(this.form[key] === 3){
                        valueText = '官方';
                    } else if(this.form[key] === 4){
                        valueText = '未修改流程的官方意图';
                    }
                }
                this.SeniorFilterParams = this.form;
                this.seniorFilterList.push({
                    value:this.form[key],
                    valueText:valueText,
                    label:label
                })
            }
            this.secniorHandler();
            this.SeniorFilterParams.pageNo = 1;
            this.SeniorFilterParams.pageSize = 30;
            this.SeniorFilterParams.apiKey = this.apiKey
            this.getDetailList('isStart');
            this.seniorFilterVisible = false;
        },
        // 删除高级筛选的条件
        handleCloseTag(tag,index){
            console.log(tag);
            this.seniorFilterList.splice(index,1);
            if(tag.label === "状态"){
                this.form.intentStatus = 1;
            } else if(tag.label === "问法"){
                this.form.question = 1;
            } else if(tag.label === "规则"){
                this.form.rule = 1;
            } else if(tag.label === "超级意图"){
                this.form.superIntent = 1;
            } else if(tag.label === "对话流程"){
                this.form.process = 1;
            } else if(tag.label === "意图类型"){
                this.form.intentType = 1;
            }
            this.secniorHandler();
            this.SeniorFilterParams = this.form;
            this.SeniorFilterParams.pageNo = 1;
            this.SeniorFilterParams.pageSize = 30;
            this.SeniorFilterParams.apiKey = this.apiKey
            this.getDetailList('isStart');
        },
        // 清除所有 高级筛选的条件
        deleteAllTag(){
            this.seniorFilterList = [];
            this.form = {
                intentStatus:1,
                question:1,
                rule:1,
                superIntent:1,
                process:1,
                intentType:1
            }
            this.SeniorFilterParams = JSON.parse(JSON.stringify(this.form));
            this.SeniorFilterParams.apiKey = this.apiKey
            this.SeniorFilterParams.pageNo = 1;
            this.SeniorFilterParams.pageSize = 30;
            this.getDetailList('isStart');
        },
        // 高级筛选---为全部时不显示
        secniorHandler(){
            let flag = false;
            this.seniorFilterList.forEach((item,index) => {
                if(item.valueText !== '全部'){
                    flag = true;
                }
            })
            this.isShowSenior = flag;
        },
        // 获取意图详情页面的列表数据
        getDetailList(type){
            this.isScrolls = true;
            let params = JSON.parse(JSON.stringify(this.SeniorFilterParams));
            if(type === 'updateIntentDetail'){
                params.intentIds = [this.updateIntentDetailId];
                params.botId = this.updateIntentDetailBotId;
                params.pageNo = 1;
                delete params.apiKey
            } else if(type === 'isStart'){
               this.$nextTick(() => {
                    console.log( document.querySelector('.list-content-detail'),'dom');
                    document.querySelector('.list-content-detail').scrollTop = 0;
               })
            }
            this.FetchPost(this.requestUrl.botHealthCheck.selectIntentDetailInBot,params).then(res => {
                if (res.code === "0") {
                    if(type && type === 'updateIntentDetail'){
                        if(res.data.list.length === 0){
                            this.intentList.forEach((item,index) => {
                                if(item.intentId == this.updateIntentDetailId){
                                    this.intentList.splice(index,1)
                                }
                            })
                        } else {
                            let ids = []
                            this.intentList.forEach((item,index) => {
                                ids.push(item.intentId)
                                if(item.intentId == this.updateIntentDetailId){
                                    item.question = res.data.list[0].question;
                                    item.rule = res.data.list[0].rule;
                                    item.superIntent = res.data.list[0].superIntent;
                                    item.hasProcess = res.data.list[0].hasProcess;
                                    item.upToStandard = res.data.list[0].upToStandard;
                                }
                            })
                            if(ids.indexOf(this.updateIntentDetailId) === -1){
                                this.nowUpdateData(res.data.list[0],params);
                            }
                        }
                        this.isScrolls = false;
                    } else {
                        this.total = res.data.total;
                        if(res.data.total === 0 ){
                            // this.loadStatus = 'noMore';
                            this.loadStatus = 'noData';
                            if(this.SeniorFilterParams.pageNo === 1){
                                this.intentList = [];
                            } else {
                                this.intentList = [...this.intentList,...res.data.list];
                            }
                        } else {
                            if(this.SeniorFilterParams.pageNo === 1){
                                this.intentList = res.data.list;
                            } else {
                                this.intentList = [...this.intentList,...res.data.list];
                            }
                            if(this.intentList.length < res.data.total){
                                this.loadStatus = 'loading';
                            
                            } else if(this.intentList.length === res.data.total){
                                this.loadStatus = 'noMore'
                            } 
                            if((this.intentList.length < res.data.total || this.intentList.length === res.data.total) && this.SeniorFilterParams.pageNo === 1){
                                this.loadStatus = 'noData';
                            }
                        }
                        this.isScrolls = false;
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        //判断滚动条是否滚到底部
        judgeScrolls(){
            this.$nextTick(() => {
                let dom = document.querySelector('.list-content-detail')
                dom.addEventListener('scroll', () => {
                    const clientHeight = dom.clientHeight;
                    const scrollTop = dom.scrollTop;
                    const scrollHeight = dom.scrollHeight;
                    if (clientHeight + scrollTop === scrollHeight) {
                        console.log('竖向滚动条已经滚动到底部')
                        if(this.intentList.length === 0){
                            // this.loadStatus = 'noMore'; 
                        } else {
                            if(this.loadStatus === 'noMore'){

                            } else {
                                console.log(this.intentList.length ,  this.total,this.isScrolls,'---');
                                if(this.intentList.length <  this.total && !this.isScrolls){
                                    this.loadStatus = 'loading';
                                    this.SeniorFilterParams.pageNo++;
                                    // console.log(666666);
                                    this.getDetailList();
                                } else {
                                    this.loadStatus === 'noMore'
                                }
                            }
                        }
                    }
                })
            })
        },
        nowUpdateData(data,params){
            // 意图状态
            if(params.intentStatus !== 1){
                if(data.upToStandard && params.intentStatus === 2){
                    this.intentList.push(data);
                }
                if(!data.upToStandard && params.intentStatus === 3){
                    this.intentList.push(data);
                }
            }
            // 问法
            if(params.question !== 1){
                if(data.question > 19  && params.question === 2){
                    this.intentList.push(data);
                }
                if(data.question < 20  && params.question === 3){
                    this.intentList.push(data);
                }
            }
            // 规则
            if(params.rule !== 1){
                if(data.rule > 0  && params.rule === 2){
                    this.intentList.push(data);
                }
                if(data.rule === 0  && params.rule === 3){
                    this.intentList.push(data);
                }
            }
            // 超级意图
            if(params.superIntent !== 1){
                if(data.superIntent  && params.superIntent === 2){
                    this.intentList.push(data);
                }
                if(!data.superIntent   && params.superIntent === 3){
                    this.intentList.push(data);
                }
            }
            // 对话流程
            if(params.process !== 1){
                if(data.hasProcess  && params.process === 2){
                    this.intentList.push(data);
                }
                if(!data.hasProcess   && params.process === 3){
                    this.intentList.push(data);
                }
            }
            
        }
    }
}
</script>
<style scoped lang="less">
.bot-heathly-drawer{
    border-left: 1px solid #E0E6F7;
    width: 420px;
    height: 100vh;
    background-color: #fff;
    z-index: 200;
    position: relative;
    box-shadow: 0px 1px 9px 0px rgba(57,63,79,0.21);
    // right: 0;
    // top: 0;
    // .bot-heathly-box{
        font-family: Microsoft YaHei;
        .el-drawer__header{
            height: 0;
            padding: 0;
            margin: 0;
            overflow: hidden; 
        }
        .bot-heathly-drawer-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: none;
            height: 45px;
            background: #F6F8FD;
            padding: 0 10px;
            svg{
                color: #FF8063;
                font-size: 16px;
                margin-right: 6px;
            }
            .title {
                display: flex;
                align-items: center;
                font-size: 16px;
                .el-icon-arrow-left{
                    margin-right: 6px;
                    cursor: pointer;
                }
            }
            .export-button{
                height: 31px;
                background: #366AFF;
                border: 1px solid #366AFF;
                border-radius: 15px;
                color: #FFFFFF;
                font-size: 12px;
                margin-left: 14px;
                text-align: center;
                line-height: 31px;
                cursor: pointer;
                padding: 0 16px;
                margin-right: 10px;
                margin-left: 10px;
            }
            .right-box{
                display: flex;
                align-items: center;
                .close {
                    flex: auto;
                    text-align: right;
                    font-size: 16px;
                    cursor: pointer;
                    &.detail{
                        margin-left: 10px;
                    }
                }
                .heathly-drawer-header-bot-list{
                    width: 180px;
                    .uf-top-bot-select {
                        display: flex;
                        align-items: center;
                    }
                    .el-input--suffix .el-input__inner {
                        padding-left: 30px;
                    }
                    /deep/  .el-select{
                        height: 31px;
                        .el-input__inner{
                            border: 1px solid #CAD8FF;
                            border-radius: 15px;
                            color: #366AFF;
                            font-size: 14px;
                            padding-left: 32px;
                        }
                    }
                }
            }
        }
        .statistics-content{
            background-color: #fff;
            width: 100%;
            height: calc(100vh - 45px);
            overflow-x: hidden;
            overflow-y: auto;
            padding-bottom: 20px;
            .score-boxs{
                margin: 0 20px;
            }
            .score-box{
                width: 100%;
                height: 80px;
                align-items: center;
                display: flex;
                justify-content: space-between;
            }
            .progress-box{
                height: 90px;
                width: 100%;
                align-items: center;
                display: flex;
                justify-content: space-between;
                .progress-img{
                    width: 39px;
                    height: 39px;
                    margin-right: 8px;
                    animation: rotating 2s linear infinite;
                    transition:color .15s linear;
                }
                .right-progress{ 
                    width: 240px;
                    text-align: left;
                    .text{
                        font-size: 15px;
                        margin-bottom: 10px;
                    }
                }
            }
            .re-check{
                width: 76px;
                height: 30px;
                background: #FFFFFF;
                border: 1px solid #A1B9FF;
                border-radius: 15px;
                text-align: center;
                line-height: 30px;
                font-size: 12px;
                color: #366AFF;
                cursor: pointer;
            }
            
            .list-box-bottom{
                // margin-top: 10px;
                .answer-item{
                    width: 80%;
                    text-align: left;
                    display: flex;
                    align-items: center;
                    .tips{
                        font-size: 12px;
                        color: #A9B3C6;
                        margin-left: 4px;
                    }
                }
                .used-components{
                    width: 20%;
                    text-align: left;
                    font-size: 12px;
                    white-space: pre-line;
                }
                .list-content{
                    width: 100%;
                   .item{
                       height: 50px;
                       width: 100%;
                        img{
                            width: 20px;
                            height: 20px; 
                            margin-right: 6px;
                        }
                   }
                }
            }
        }
        .intent-detail-content{
            .intent-standard-tips{
                background: #FFF8F4;
                border: 1px solid #FFD9C5;
                border-radius: 5px;
                margin-bottom: 20px;
                text-align: left;
                color: #FF9555;
                font-size: 12px;
                padding: 12px;
                margin:20px;
            }
            .senior-filter-header-tag{
                min-height: 50px;
                display: flex;
                align-items: center;
                padding: 0 16px;
                background-color: white;
                position: relative;
                border-bottom: 1px solid #EBEDF4;
                border-top: 1px solid #EBEDF4;
                .showTag{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }
                /deep/ .el-tag .el-tag__close{
                    color: white;
                }
                .el-tag{
                    background-color: #366AFF;
                    border-color: #366AFF;
                    color: white;
                    margin-bottom: 4px;
                    margin-top: 4px;
                }
                .delete-all{
                    color: #366AFF;
                    position: absolute;
                    right: 16px;
                }
            }
            .intent-table{
                width: 100%;
                text-align: left;
                .look-more{
                    margin-top: 10px;
                    width: 480px;
                    height: 33px;
                    background: #FFFFFF;
                    margin-top: 4px;
                    color: #A9B3C6;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 10px; transform: scale(0.83333); transform-origin: 0 0;
                    i{
                        margin-right: 6px;
                    }
                }
                .list-content-detail{
                    height: calc(100vh - 190px);
                    overflow-x: hidden;
                    overflow-y: auto;
                    &.no-data{
                       display: flex;
                       align-items: center;
                       justify-content: center;
                       .no-data-box{
                           display: flex;
                           align-items: center;
                           justify-content: center;
                           flex-direction: column;
                           div{
                               color:#616161;
                               font-size: 16px;
                               margin-top: 30px;
                           }
                       }
                   }
                   &.seniorFilterHeight{
                        height: calc(100vh - 250px);
                   }
                }
            }
            .intent-name{
                .name{
                    color: #366AFF;
                    margin-bottom: 2px;
                }
                .intent-classifi{
                    color: #9397a3;
                }
            }
            .super-status>div{
                width: 48px;
                height: 22px;
                background: #FFEEE0;
                border-radius: 11px;
                text-align: center;
                line-height: 22px;
                font-size: 12px;
                &.open{
                    background: #FFEEE0;
                    color: #FF7200;
                }
                &.close{
                    background: #EEEEEE;
                    color: #9DA5BF;
                }
            }
            .ask-status>div{
                width: 43px;
                height: 18px;
                background: #FFF6F7;
                border-radius: 2px;
                text-align: center;
                line-height: 19px;
                font-size: 12px;
                &.normal{
                    background: #DBE4FF;
                    border: 1px solid #DBE4FF;
                    color: #366AFF;
                }
                &.warn{
                    background: #FFF6F7;
                    border: 1px solid #FFDFE1;
                    color: #FE5965;
                }
            }
            .is-standard{
                .el-icon-circle-close{
                    color: #FE5965;
                }
                .el-icon-circle-check{
                    color: #04B051;
                }
            }
        }
        .list-box,.list-box-bottom,.intent-table{
            .list-header{
                height: 34px;
                background: #F6F8FD;
                display: flex;
                align-items: center;
                font-weight: bold;
                color: #000000;
                text-align: left;
                >div{
                    white-space: nowrap;
                }
            }
            .list-header,.list-content{
                padding: 0 20px;
                .detection-item,.standard,.standard-text{
                    width: 43%;
                    text-align: left;
                    white-space: nowrap;
                }
                .detection-item-text{
                  width: 43%;
                  text-align: left;
                }
                .standard,.standard-text{
                    width: 45%;
                }
                .operation,.operation-text{
                    width: 10%;
                }
                .item{
                    display: flex;
                    align-items: center;
                    height: 50px;
                    font-size: 12px;
                    border-bottom: 1px solid #E0E6F7;
                    cursor: pointer;
                    .standard-text{
                        display: flex;
                        align-items: center;
                        .el-icon-circle-close{
                            color: #FE5965;
                        }
                        .el-icon-circle-check{
                            color: #04B051;
                        }
                        .now-num{
                            // font-size: 12px;
                            color: #000;
                            margin-left: 4px;
                            font-family: Arial;
                        }
                        .standard-num{
                            color: #9397a3;
                            // font-size: 12px;
                            font-family: Arial;
                        }
                        .get-score{
                            height: 20px;
                            background: #ECF1FF;
                            border-radius: 10px;
                            padding: 0 7px;
                            line-height: 20px;
                            text-align: center;
                            color: #366AFF;
                            font-size: 14px;
                            font-family: Arial;
                            margin-left: 10px;
                            &.red{
                                background-color: #FFF6F7;
                                color: #FE5965;
                            }
                        }
                        .train{
                            display: flex;
                            flex-direction: column;
                            margin-left: 4px;
                            .week-times{
                                font-size: 12px;
                                color: #A9B3C6;
                            }
                            .now-num{
                                margin-left: 0;
                            }
                        }
                    }
                    .operation-text{
                        //width: 44px;
                        padding: 0 6px;
                        height: 24px;
                        background: #FFFFFF;
                        border: 1px solid #A1B9FF;
                        border-radius: 12px;
                        //line-height: 24px;
                        text-align: center;
                        cursor: pointer;
                        font-size: 12px;
                        color: #366AFF;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.use-text{
                            //width: 50px;
                            border: none;
                        }
                    } 
                    &:last-child{
                        // border: none;
                    } 
                }
                .answer-item .item{
                    border: none;
                }
            }
        }
    // }
    /deep/.super-search{
        #popup-assembly{
            width: 700px;
        }
        .senior-filter{
        .senior-filter-item{
            display: flex;
            align-items: center;
            height: 50px;
            width: 100%;
            .label{
                width: 62px;
                text-align: right;
                margin-right: 20px;
            }
            .el-radio-group{
                background-color: #FBFCFD;
                height: 40px;
                padding-left: 20px;
                width: calc(100% - 62px);
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .el-radio{
                    width: 76px;
                }
            }

        }
    }
    }
    



}

</style>