<template>
    <div class="no-data-echarts">
        <img src="../../../assets/images/no-data.png" alt="">
        <div class="text">{{$t('common.empty')}}</div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.no-data-echarts{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    img{
        width: 251px;
        height: 182px;
    }
    .text{
        font-size: 16px;
        color: #616161;
        margin-top: 30px;
    }
}

</style>