<template>
    <div id="statistics-area">
        <div v-show="areaList.length != 0" class="map-outer">
            <div class="map-header">{{$t('statisticsUp.area.title')}}</div>
            <div class="data-type">
                <div @click="changeDataType(0)" class="data-type-cell">
                    <span
                        :class="[
                            'data-type-color',
                            activeDataType == 0 ? 'data-type-color-active' : '',
                        ]"
                    ></span>
                  {{$t('statisticsUp.area.numberOfConsultations')}}
                </div>
                <div @click="changeDataType(1)" class="data-type-cell">
                    <span
                        :class="[
                            'data-type-color',
                            activeDataType == 1 ? 'data-type-color-active' : '',
                        ]"
                    ></span
                    >{{$t('statisticsUp.area.consultationSessions')}}
                </div>
                <div @click="changeDataType(2)" class="data-type-cell">
                    <span
                        :class="[
                            'data-type-color',
                            activeDataType == 2 ? 'data-type-color-active' : '',
                        ]"
                    ></span
                    >{{$t('statisticsUp.area.consultationMessages')}}
                </div>
            </div>
            <div id="map-content" class="map-content">
                <div
                    class="map-container"
                    :style="{
                        width: '600px',
                        height: '600px',
                        marginTop: '-100px',
                    }"
                    ref="myEchart"
                    id="map-container"
                ></div>
            </div>
        </div>
        <div class="area-list-outer">
            <div class="area-list-header">
                <span class="area-list-header-name">{{$t('statisticsUp.area.region')}}</span>
                <div  @click="setArea" class="operation-btn">
                    <i class="iconfont guoran-a-24-06"></i>
                    <span>{{$t('statisticsUp.area.setRegion')}}</span>
                </div>
                <!-- <span class="area-list-header-icon">
                    <em @click="setArea" class="el-icon-s-tools"></em>
                </span> -->
            </div>
            <ul v-if="areaList.length != 0" class="area-list-content">
                <li
                    v-for="(percentItem, percentIndex) in areaList"
                    :key="percentIndex"
                    class="percent-cell"
                >
                    <div class="percent-cell-name">
                        <div class="num">{{ percentItem.index }}</div>
                        <span>{{ percentItem.area }}</span>
                    </div>
                    <!-- <span 
                        >{{ percentItem.index }}.{{ percentItem.area }}</span
                    > -->
                    <div v-if="activeDataType == 0" class="el-progress-box">
                        <el-progress
                            define-back-color="#F1F4FA"
                            :percentage="
                                (percentItem.userCountRate * 100).toFixed(2)
                            "
                        ></el-progress>
                        <span class="percent-cell-acount">{{
                            percentItem.userCount
                        }}</span>
                    </div>
                    <div v-if="activeDataType == 1" class="el-progress-box">
                        <el-progress
                            define-back-color="#F1F4FA"
                            :percentage="
                                (percentItem.sessionCountRate * 100).toFixed(2)
                            "
                        ></el-progress>
                        <span class="percent-cell-acount">{{
                            percentItem.sessionCount
                        }}</span>
                    </div>
                    <div v-if="activeDataType == 2" class="el-progress-box">
                        <el-progress
                            :percentage="
                                (percentItem.msgCountRate * 100).toFixed(2)
                            "
                        ></el-progress>
                        <span class="percent-cell-acount">{{
                            percentItem.msgCount
                        }}</span>
                    </div>
                </li>
            </ul>
            <div class="number font14" v-if="areaList.length == 0">
                {{$t('common.empty')}}
            </div>
        </div>


        <popup @closeEvent="setAreaDialogVisible = false" v-if="setAreaDialogVisible" class="set-area-popup">
            <div slot="popup-name">{{$t('statisticsUp.area.setRegion')}}</div>
            <div slot="popup-con">
                <div class="set-area">
                    <div class="set-area-type">
                        <span class="set-area-type-pre">{{$t('statisticsUp.area.regionalCalculationMethod')}}：</span>
                        <el-radio-group v-model="areaType">
                            <el-radio :label="0">{{$t('statisticsUp.area.IP')}}</el-radio>
                            <el-radio :label="1">{{$t('statisticsUp.area.custom')}}</el-radio>
                        </el-radio-group>
                    </div>

                    <div class="set-type-title">
                        <div class="set-type-title-type">{{$t('statisticsUp.area.areaName')}}</div>
                        <div :class="['set-type-title-city',areaType === 1 ? 'min' : 'max']"
                            >{{$t('statisticsUp.area.bindCity')}}</div
                        >
                        <div v-if="areaType == 1" class="set-type-title-intent"
                            >{{$t('statisticsUp.area.bindOrganization')}}</div
                        >
                        <!-- <div
                            v-if="list.length != 1"
                            class="set-type-title-handle"
                        ></div> -->
                    </div>
                    <div class="set-type-list">
                        <div
                            v-for="(cell, index) in list"
                            :key="index"
                            class="set-type-list-cell"
                        >
                            <div class="set-type-title-type">
                                <el-input
                                    v-model="cell.name"
                                    maxlength="10"
                                    :placeholder="$t('statisticsUp.area.areaNamePlaceholder')"
                                ></el-input>
                            </div>
                            <div :class="['set-type-title-city',areaType === 1 ? 'min' : 'max']">
                                <el-cascader
                                    :class="'cascader' + index"
                                    :ref="'cascader' + index"
                                    v-model="cell.cityCodes"
                                    :rows="3"
                                    :options="city"
                                    :show-all-levels="false"
                                    :collapse-tags="true"
                                    :placeholder="$t('statisticsUp.area.areaNamePlaceholder')"
                                    :props="{
                                        emitPath: false,
                                        label: 'name',
                                        value: 'code',
                                        children: 'cityList',
                                        rows: '3',
                                        multiple: true,
                                    }"
                                ></el-cascader>
                            </div>
                            <div
                                v-if="areaType == 1"
                                class="set-type-title-intent"
                            >
                                <div class="bind-bot-self-tree-boxs">
                                    <div class="input-divs " @click="openStaffPopup(cell,index)">
                                        <div v-if="cell.ext.dept && cell.ext.dept.length > 0"
                                            class="one-column-ellipsis">
                                            <span class="have-checked-intent" v-for="(tagCell, tagCellIndex) in cell.ext.dept" :key="tagCellIndex">
                                                <!-- 0:部门，1:员工，2:上下游部门，3:上下游成员 -->
                                                <span class="data-label" v-if="tagCell.selfTypes === 0 || !tagCell.isWechat">
                                                    <span v-if="tagCell.staffType !== 'staff-t'">{{ tagCell.label }}</span>
                                                    <span v-else>
                                                        <open-data :type="'departmentName'" :openid="tagCell.label"></open-data>
                                                    </span>
                                                </span>
                                                <span v-else>{{ tagCell.name }}</span>
                                                <span v-if="tagCellIndex + 1 < cell.ext.dept.length">,</span>
                                            </span>

                                        </div>
                                        <div class="placeholder-text" v-else>{{$t('statisticsUp.area.all')}}</div>
                                        <i class="el-icon-arrow-down"></i>
                                    </div>
                                    <div
                                        v-show="activeTreeIndex == index"
                                        @click.stop="selectOrg"
                                        class="bind-bot-self-tree"
                                    >
                                        <!-- <el-tree
                                            :data="orgTree"
                                            node-key="id"
                                            :default-expand-all="
                                                defaultExpandAll
                                            "
                                            :props="defaultProps"
                                            :expand-on-click-node="false"
                                        >
                                            <span
                                                class="custom-tree-node"
                                                slot-scope="{ node, data }"
                                            >
                                                <span>
                                                    <el-checkbox
                                                        @change="
                                                            changeCheckedOrg(
                                                                node,
                                                                data,
                                                                index
                                                            )
                                                        "
                                                        v-model="data.checked"
                                                    ></el-checkbox>
                                                </span>
                                                <span
                                                    style="
                                                        display: inline-block;
                                                        margin-left: 6px;
                                                    "
                                                    >{{ node.label }}</span
                                                >
                                            </span>
                                        </el-tree> -->
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="list.length != 1"
                                class="set-type-title-handle"
                            >
                                <span
                                    @click="deleteSetType(index)"
                                    class="el-icon-delete"
                                ></span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="add-set-type">
                        <el-button
                            @click="addType"
                            size="mini"
                            type="primary"
                            round
                            >添加区域</el-button
                        >
                    </div> -->
                     <div class="add-set-type">
                        <div class="add-btn guoran-tongyichicun-16-13-xinjian iconfont"  @click="addType"></div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="setAreaDialogVisible = false" plain round class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button class="confirm-btn" type="primary" @click="saveSetArea" round style="padding:0 !important;">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
        <popup @closeEvent="dialogMemberTree = false" v-if="dialogMemberTree" class="choose-consultant-popup">
            <div slot="popup-name">{{$t('statisticsUp.area.dialogMemberTreeTip')}}</div>
            <div slot="popup-con" class="member-contanier">
                <div class="choose-style">
                    <div>{{$t('statisticsUp.area.dialogMemberTreeTip')}}</div>
                    <div class="radio-box">
                        <el-radio v-model="chooseStyle" label="all" >{{$t('statisticsUp.area.all')}}</el-radio>
                        <el-radio v-model="chooseStyle" label="self" >{{$t('statisticsUp.area.custom')}}</el-radio>
                    </div>
                </div>
                <staffCustomer 
                    dataType="staff" 
                    chooseType="dept"
                    v-if="chooseStyle === 'self'" 
                    :isMultiple="true" 
                    :selectClient="selectClient"
                    @onNodeClick="onNodeClick" 
                    :memberInfo4Client="memberInfo4Client"
                    ref="staffCustomer"></staffCustomer>
            </div>
            <div slot="dialog-footer">
                <el-button @click="cancelChooseMember" plain class="cancel-btn">{{$t('common.cancel')}}</el-button>
                <el-button type="primary" @click="preserStaff" class="confirm-btn">{{$t('common.confirm')}}</el-button>
            </div>
        </popup>
               


    </div>
</template>

<script>
import * as echarts from "echarts";
import "./china.js";
import { citys } from "./chinaCity";
import popup from '../../../components/popup.vue'
import staffCustomer from '../../../components/staffCustomer'
export default {
    props: ["scopeType", "scope", "areaList"],
    components:{popup,staffCustomer},
    data() {
        return {
            myChart: "",
            // 组织架构 start
            activeTreeIndex: null,
            defaultCheckedKeys: [5],
            orgTree: [],
            defaultExpandAll: false,
            defaultProps: {
                children: "children",
                label: "name",
            },
            // 组织架构 end
            setAreaDialogVisible: false,
            areaType: 0,
            nodeTree: [],
            intentTree4Radio: [],
            list: [
                {
                    name: "",
                    cityCodes: [],
                    deptIds: [],
                    ext: {},
                },
            ],
            city: citys(),
            myEchart: "",
            colors: [
                "#f4e925",
                "#ea66a6",
                "#decb00",
                "#de773f",
                "#b4532a",
                "#f173ac",
                "#ffe600",
                "#f36c21",
                "#ffd400",
                "#f2eada",
                "#f0dc70",
                "#b4532a",
                "#f173ac",
                "#ffe600",
                "#f36c21",
                "#ffd400",
                "#c99979",
                "#90d7ec",
                "#f2eada",
                "#f0dc70",
                "#decb00",
                "#de773f",
                "#2585a6",
                "#fcf16e",
                "#b7704f",
                "#4e72b8",
                "#feeeed",
                "#feeeed",
                "#8e3e1f",
                "#dbce8f",
                "#f15a22",
                "#009ad6",
                "#ffce7b",
                "#8552a1",
                "#ea66a6",
                "#84bf96",
                "#cde6c7",
                "#80752c",
                "#7d5886",
                "#d3d7d4",
            ],
            activeDataType: 0,
            selectClient: [],
            memberInfo4Client: {
                clientInfo: [], // 选中数据
            },
            checkedList:[],
            chooseStyle: "all",
            chooseStyleOld:"all",
            dialogMemberTree:false,
            activeItem:{},
            activeIndex:-1
        };
    },
    methods: {
        cancelChooseMember(){
            this.chooseStyle = this.chooseStyleOld;
            this.dialogMemberTree = false;
        },
        openStaffPopup(item,index) {
            console.log(item,index,'item');
            this.chooseStyle = item.allDept ? 'all' : 'self'
            this.activeItem = JSON.parse(JSON.stringify(item));
            this.activeIndex = index;
            this.memberInfo4Client.clientInfo = item.ext.dept || [];
            this.dialogMemberTree = true;
        },
        preserStaff() {
            this.chooseStyleOld = this.chooseStyle;
            if (this.chooseStyle === 'all') {
                this.checkedList = [];
            }
            this.memberInfo4Client.clientInfo = this.checkedList || [];
            this.dialogMemberTree = false;
            this.activeItem.ext.dept = this.checkedList;
            this.activeItem.allDept = this.chooseStyle === 'all' ? true : false;
            this.$set(this.list,this.activeIndex,JSON.parse(JSON.stringify(this.activeItem)))
        },
        onNodeClick(node, type, memberInfo) {
            if (node.checked) {
                this.checkedList.push(node);
            } else {
                this.checkedList.forEach((v, i) => {
                    if (node.id == v.id) {
                        this.checkedList.splice(i, 1)
                    }
                })
            }
            this.$refs.staffCustomer.checkMemberList = [];
            this.checkedList = this.handlerArr(this.checkedList)
        },
        handlerArr(tempArr) {
            let result = [];
            let obj = {};
            for (let i = 0; i < tempArr.length; i++) {
                if (!obj[tempArr[i].id]) {
                    result.push(tempArr[i]);
                    obj[tempArr[i].id] = true;
                };
            };
            return result;
        },
        changeDataType(value) {
            console.log(value);
            this.activeDataType = value;
            this.test(this.myChart, this.activeDataType);
        },
       
        test(mycharts, dataType) {
            var option = {
                // backgroundColor: "#404a59", //整个画布背景
                //标题组件
                title: {
                    text: null,
                    subtext: null,
                    sublink: null,
                    left: "center",
                    textStyle: {
                        color: "#fff",
                    },
                },
                //提示框组件
                tooltip: {
                    trigger: "item",
                    confine: true,
                    formatter: (p) => {
                        //自定义提示信息
                        let dataCon = p.data;
                        var txtCon =
                            dataCon.area +
                            "【" +
                            dataCon.name +
                            "】" +
                            "<br>值:" +
                            dataCon.value[2];
                        return txtCon;
                    },
                },
                //左下lengend
                // visualMap: {
                //     // min: 0, //最小
                //     // max: 10, //最大
                //     // splitNumber: 5, //共分5层
                //     // color: ["#ff6300", "#eac736", "#50a3ba"], //颜色从高到低依次渐变
                //     // textStyle: {
                //     //     color: "#fff",
                //     // },
                // },
                //地图区域样式
                geo: {
                    map: "china",
                    label: {
                        emphasis: {
                            show: false,
                        },
                    },
                    itemStyle: {
                        //未激活样式
                        normal: {
                            areaColor: "#366AFF",
                            borderColor: "#ffffff",
                        },
                        //激活样式
                        emphasis: {
                            areaColor: "#426ab3",
                        },
                    },
                },
                //数据
                series: this.formatterCityData(this.areaList, dataType),
            };
            mycharts.setOption(option);
        },
        // 组织架构 start
        // 获取部门树
        getDepartmentTree() {
            this.FetchGet(this.requestUrl.bindV2.departmentTree).then((res) => {
                if (res.code === "0") {
                    let arr = res.data;
                    if (arr[0] && arr[0].total && arr[0].total > 300) {
                        this.defaultExpandAll = false;
                    } else {
                        this.defaultExpandAll = true;
                    }
                    const formatDepartmentTree = (arr) => {
                        arr.forEach((item) => {
                            item.name = item.label;
                            item.checked = false;
                            if (item.children && item.children != 0) {
                                item.children = formatDepartmentTree(
                                    item.children.filter(
                                        (cell) => cell.type != 0
                                    )
                                );
                            }
                        });
                        return arr;
                    };
                    this.orgTree = formatDepartmentTree(
                        arr.filter((item) => item.type != 0)
                    );
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        setActiveTreeIndex(item,index) {
            console.log(item);
            this.activeItem = item;
            this.checkedList = []
            // let arr = [];
            // if (this.list[index].ext.dept == null) {
            //     this.list[index].ext.dept = [];
            // }
            // this.list[index].ext.dept.forEach((item) => {
            //     arr.push(item.id);
            // });
            // setTimeout(() => {
            //     this.initShowOrTree(arr, true, "init");
            //     this.activeTreeIndex = index;
            // }, 100);
            this.dialogMemberTree = true;
        },
        changeCheckedOrg(node, data, index) {
            if (data.checked) {
                this.list[index].ext.dept.unshift(data);
            } else {
                this.list[index].ext.dept.forEach((cell, cellIndex) => {
                    if (cell.id == data.id) {
                        this.list[index].ext.dept.splice(cellIndex, 1);
                    }
                });
            }
            this.list[index].ext.dept = [...this.list[index].ext.dept];
            this.list = [...this.list];
        },
        handleCloseOrganization(index, tagCellIndex) {
            this.initShowOrTree(
                [this.list[index].ext.dept[tagCellIndex].id],
                false,
                "close"
            );
            this.list[index].ext.dept.splice(tagCellIndex, 1);
        },
        initShowOrTree(handleIdArr, isChecked, type) {
            const formOrgTree = (arr, handleIdArr, isChecked) => {
                if (type == "close") {
                    arr.forEach((item) => {
                        if (handleIdArr.indexOf(item.id) != -1) {
                            item.checked = isChecked;
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        } else {
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        }
                    });
                } else {
                    arr.forEach((item) => {
                        if (handleIdArr.indexOf(item.id) != -1) {
                            item.checked = isChecked;
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        } else {
                            item.checked = !isChecked;
                            if (item.children) {
                                formOrgTree(
                                    item.children,
                                    handleIdArr,
                                    isChecked
                                );
                            }
                        }
                    });
                }

                return arr;
            };
            this.orgTree = [
                ...formOrgTree(this.orgTree, handleIdArr, isChecked),
            ];
        },

        // 组织架构 end
        setArea() {
            this.FetchGet(
                this.requestUrl.statistics.getAreaConfigInfo + this.scope
            ).then((res) => {
                if (res.code === "0") {
                    this.areaType = res.data.mode == null ? 0 : res.data.mode;
                    this.list = res.data.items == null ? [] : res.data.items;
                    this.list && this.list.length > 0 && this.list.forEach((item,index) => {
                        for (const key in res.data.deptType) {
                            if (Object.hasOwnProperty.call(res.data.deptType, key)) {
                                const element = res.data.deptType[key];
                                if(item.ext.dept){
                                    item.ext.dept.forEach((v,i) => {
                                        // if(v.selfTypes){
                                        //     v.selfTypes = v.originData && v.originData.wechatBotDTO && v.originData.wechatBotDTO.wechatType === 0 ? "staff-t" : "staff"
                                        // }
                                        if(v.id == key){
                                            v.selfTypes =  element === 1 ? 0 : 9;
                                        } else {
                                            v.selfTypes = 9
                                        }
                                    })
                                } else {
                                  
                                }
                                
                            }
                        }
                        
                    })
                    this.setAreaDialogVisible = true;
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        saveSetArea() {
            let flag = true;
            this.list.forEach((item,index) => {
                let ckeckedList = []
                ckeckedList = this.$refs['cascader'+ index][0].getCheckedNodes();
                if(ckeckedList[0].level === 1){
                    item.cityCodes.unshift(ckeckedList[0].value);
                    item.cityCodes = Array.from(new Set(item.cityCodes))
                }
            });
            if (this.areaType == 0) {
                this.list.forEach((item,index) => {
                    if (item.name.trim() == "" || item.cityCodes.length == 0) {
                        this.$message.error(this.$t('statisticsUp.area.areaMsg'));
                        flag = false;
                    }
                });
            }
            let copyList = JSON.parse(JSON.stringify(this.list))
            if (this.areaType == 1) {
                copyList.forEach((item,index) => {
                    if(item.allDept){
                        item.deptIds = [];
                        item.ext.dept = [];
                    } else {
                        if (item.name.trim() == "" || item.cityCodes.length == 0 || item.ext.dept && item.ext.dept.length == 0) {
                            this.$message.error(this.$t('statisticsUp.area.areaMsg'));
                            flag = false;
                        }
                        item.deptIds = item.ext.dept && item.ext.dept.map((cell) => {
                            return cell.id;
                        });
                        console.log( item.ext.dept && item.ext.dept.length > 0,index);
                        item.ext.dept && item.ext.dept.length > 0 && item.ext.dept.forEach((v,i) => {
                            if(v.selfType){
                                delete  v.selfType; 
                            }
                            if(v.selfTypes){
                                delete  v.selfTypes; 
                            }
                        })
                    }
                });
            }
            console.log(copyList,'this.list');
            flag &&
                this.FetchPost(
                    this.requestUrl.statistics.areaConfigInfo +
                        this.scope +
                        "?scopeType=" +
                        this.scopeType,
                    {
                        items: copyList,
                        mode: this.areaType,
                    }
                ).then((res) => {
                    if (res.code === "0") {
                        this.$message.success(this.$t('statisticsUp.area.setSuccess'));
                        this.setAreaDialogVisible = false;
                        this.$emit("reload13");
                    } else {
                        this.$message.error(res.message);
                    }
                });
        },
        cancelSaveSetType() {
            this.$emit("cancelSaveSetType");
        },
        addType() {
            this.list.push({
                name: "",
                cityCodes: [],
                deptIds: [],
                ext: {},
                allDept:true
            });
        },
        setAreaList(list) {
            this.areaList = list;
            this.test(this.myChart, this.activeDataType);
        },
        deleteSetType(index) {
            this.list.splice(index, 1);
        },
        formatterCityData(param, dataType) {
            let tampArr = [];
            param.forEach((area, areaIndex) => {
                let tampCell = [];
                area.city.forEach((city, cityIndex) => {
                    let tampobj = {
                        name: city.name,
                        area: area.area,
                    };
                    if (dataType == 0) {
                        tampobj.value = city.geo
                            .split(",")
                            .concat(area.userCount);
                    } else if (dataType == 1) {
                        tampobj.value = city.geo
                            .split(",")
                            .concat(area.sessionCount);
                    } else {
                        tampobj.value = city.geo
                            .split(",")
                            .concat(area.msgCount);
                    }
                    tampCell.push(tampobj);
                });
                tampArr.push(tampCell);
            });
            let arr = [];
            tampArr.forEach((area, index) => {
                arr.push({
                    name: "",
                    type: "effectScatter",
                    coordinateSystem: "geo",
                    data: area,
                    encode: {
                        value: 2,
                    },
                    symbolSize: function (val) {
                        let num = 7;
                        if (dataType == 0) {
                            num += val[2] / 100;
                        } else if (dataType == 1) {
                            num += val[2] / 1000;
                        } else {
                            num += val[2] / 10000;
                        }
                        if (num > 15) {
                            num = 15;
                        }
                        return num;
                    },
                    showEffectOn: "emphasis",
                    rippleEffect: {
                        brushType: "stroke",
                    },
                    hoverAnimation: true,
                    label: {
                        formatter: "{b}",
                        position: "right",
                        show: false,
                    },
                    itemStyle: {
                        color: this.colors[index],
                        shadowBlur: 10,
                        shadowColor: this.colors[index],
                    },
                    zlevel: 1,
                });
            });
            return arr;
        },
    },
    mounted() {
        // this.getDepartmentTree();
        window.addEventListener("click", () => {
            this.activeTreeIndex = null;
        });
        this.$nextTick(() => {
            var chartDom = document.getElementById("map-container");
            let myChart = this.$echarts.init(chartDom);
            this.myChart = myChart;
            this.test(myChart, 0);
        });
    },
};
</script>

<style lang="less">
@import "./../../../assets/less/statistics/statisticsCommon.less";
.anchorBL {
    display: none !important;
}
.font14 {
    height: 33px;
    font-size: 14px !important;
    color: #888888;
}
#statistics-area {
    // min-width: 1000px;
    width: calc(100% - 40px);
    display: flex;
    background-color: white;
    padding: 20px;
    justify-content: space-around;
    overflow-x: auto;
    font-size: 14px;
    color: #000;
    .set-area-type {
        margin-bottom: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 40px;
        background: #FBFCFD;
        border-radius: 5px;
        font-weight: bold;
        margin-bottom: 22px;
        .set-area-type-pre {
            flex: none;
            width: 180px;
            text-align: left;
        }
    }
    .set-type-list {
        margin-bottom: 4px;
        background: #FBFCFD;
        border-radius: 5px;
        padding: 14px;
        max-height: 350px;
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .add-set-type{
        display: flex;
        justify-content: center;
        .add-btn{
            width: 31px;
            height: 31px;
            background: #FFFFFF;
            border: 1px solid #D5E0FF;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            cursor: pointer;
            color: #366AFF;
        }
    }
   
    .set-type-title,
    .set-type-list-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 6px;
        .el-tag {
            margin-left: 6px;
            height: 24px;
            line-height: 24px;
            background: #366AFF;
            border-radius: 3px;
            color: #fff;
            .el-tag__close{
                color: #fff;
                background: #366AFF;
                transform: scale(1);
            }
        }
        .set-type-title-type {
            flex: none;
            width: 180px;
            text-align: left;
            margin-right: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            
        }
        .set-type-title-city {
            flex: auto;
            text-align: left;
            margin-right: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 180px;
            .el-cascader{
                width: 100%;
            }
            &.min{
                width: 180px;
            }
            &.max{
                width: 460px;
            }
            
           
        }
        .set-type-title-intent {
            flex: none;
            width: 280px;
            text-align: left;
            margin-right: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .bind-bot-self-tree-boxs {
                text-align: left;
                flex: auto;
                position: relative;
                padding: 0;
                height: 40px;
                .input-divs{
                    width: 255px;
                    height: 38px;
                    background: #FFFFFF;
                    border: 1px solid #DCDFE6;
                    border-radius: 5px;
                    // line-height: 38px;
                    display: flex;
                    align-items: center;
                    padding: 0 12px;
                    // align-items: center;
                    cursor: pointer;
                    .placeholder-text{
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #606266;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                    }
                    .have-checked-intent{
                        margin-right: 4px;
                    }
                    .el-icon-arrow-down{
                        font-size: 14px;
                        color: #717B90;
                        margin-left: 4px;
                        margin-right: 0;
                    }
                    .one-column-ellipsis{
                        text-align: left;
                        width: 240px;
                    }
                }
                .bind-bot-self-tree {
                    padding-top: 5px;
                    padding-left: 6px;
                    z-index: 99999999999999999;
                    position: relative;
                    top: 3px;
                    background-color: white;
                    border-left: solid 1px #e4e7ed;
                    border-right: solid 1px #e4e7ed;
                    border-bottom: solid 1px #e4e7ed;
                    border-radius: 6px;
                    max-height: 400px;
                    overflow: auto;
                }
            }
        }
        .set-type-title-handle {
            flex: none;
            // width: 30px;
            text-align: center;
            line-height: 28px;
            color: #366AFF;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
        }
        .el-input__inner{
            // height: 38px !important;
            color: #000;
        }
        
    }
    .set-type-title{
        height: 40px;
        background: #F6F8FD;
        border-radius: 5px 5px 0px 0px;
        font-weight: 700;
        margin-bottom: 0;
        padding:0 14px;
    }
    .map-outer {
        flex: none;
        width: 650px;
        // width: 45%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .map-header {
            flex: none;
            height: 30px;
            text-align: left;
        }
        .data-type {
            z-index: 1;
            margin: 0 auto;
            width: 360px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
            .data-type-cell {
                cursor: pointer;
                display: flex;
                justify-content: flex-start;
                height: 16px;
                line-height: 16px;
                .data-type-color {
                    width: 32px;
                    height: 16px;
                    background-color: lightgray;
                    border-radius: 4px;
                    margin-right: 4px;
                }
                .data-type-color-active {
                    background-color: #366AFF;
                }
            }
        }
        .map-content {
            flex: auto;
        }
    }
    .area-list-outer {
        width: 45%;
        margin-left: 20px;
        flex: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .area-list-header {
            margin-bottom: 16px;
            height: 30px;
            line-height: 30px;
            flex: none;
            display: flex;
            justify-content: space-between;
            .area-list-header-name {
                width: 50px;
                flex: none;
                border-bottom: solid 2px #366AFF;
            }
            .area-list-header-icon {
                flex: auto;
                text-align: right;
                border-bottom: solid 2px #f2f6fc;
                color: #366AFF;
                em {
                    cursor: pointer;
                    font-size: 20px;
                }
            }
            .operation-btn{
                height: 29px;
                background: #FFFFFF;
                border: 1px solid #E0E6F7;
                border-radius: 5px;
                padding: 0 10px; 
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #616161;
                cursor: pointer;
                .iconfont{
                    color: #616161;
                    font-size: 14px;
                    margin-right: 2px;
                }
                span{
                    color: #616161;
                    font-size: 14px;
                }
            }
        }
        .area-list-content {
            flex: auto;
            .percent-cell {
                display: flex;
                justify-content: flex-start;
                height: 34px;
                margin-top: 10px;
                margin-bottom: 14px;
                .el-progress__text {
                    width: 60px;
                    margin-left: 10px;
                }
                .el-progress-bar {
                    margin-right: -70px;
                    padding-right: 70px;
                }
                .percent-cell-name {
                    margin-top: -4px;
                    flex: none;
                    text-align: left;
                    display: flex;
                    margin-right: 30px;
                    .num{
                        height: 18px;
                        padding: 0 6px;
                        background: #FFFFFF;
                        border: 1px solid #366AFF;
                        border-radius: 5px;
                        font-size: 12px;
                        color: #366AFF;
                        margin-right: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .el-progress-box {
                    flex: auto;
                    min-width: 200px;
                    display: flex;
                    justify-content: flex-start;
                    .el-progress {
                        flex: auto;
                        /* 渐变进度条 */
                        .el-progress-bar__inner{
                            background-color: unset;
                            background: linear-gradient(90deg, #458FFF, #09DDD5);
                        }
                        .el-progress__text{
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            color: #000000;
                        }

                    }
                    .percent-cell-acount {
                        width: 60px;
                        flex: none;
                        text-align: right;
                        font-size: 12px;
                        color: #606266;
                    }
                }
            }
        }
    }
    .set-area-popup{
        #popup-assembly{
            width: 830px;
        }
    }
}
</style>