var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"set-type"}},[_vm._m(0),_c('div',{staticClass:"set-type-list"},_vm._l((_vm.list),function(cell,index){return _c('div',{key:index,staticClass:"set-type-list-cell"},[_c('div',{staticClass:"set-type-title-type"},[_c('el-input',{attrs:{"size":"mini","maxlength":"10","placeholder":"请输入问题类型"},model:{value:(cell.name),callback:function ($$v) {_vm.$set(cell, "name", $$v)},expression:"cell.name"}})],1),_c('div',{staticClass:"set-type-title-intent"},[_c('el-cascader',{key:'list_' + index,attrs:{"size":"mini","rows":3,"options":_vm.intentTree4Radio,"show-all-levels":false,"collapse-tags":true,"placeholder":"请选择意图","props":{
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                            rows: '3',
                            multiple: true,
                        }},model:{value:(cell.intentIds),callback:function ($$v) {_vm.$set(cell, "intentIds", $$v)},expression:"cell.intentIds"}})],1),(_vm.list.length != 1)?_c('div',{staticClass:"set-type-title-handle"},[_c('span',{staticClass:"el-icon-delete",on:{"click":function($event){return _vm.deleteSetType(index)}}})]):_vm._e()])}),0),_c('div',{staticClass:"add-set-type"},[_c('el-button',{attrs:{"size":"mini","type":"primary","round":""},on:{"click":_vm.addType}},[_vm._v("添加问题类型")])],1),_c('div',{staticClass:"set-type-dialog-footer"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.cancelSaveSetType}},[_vm._v("取 消")]),_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":_vm.saveSetType}},[_vm._v("确 定")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"set-type-title"},[_c('span',{staticClass:"set-type-title-type"},[_vm._v("问题类型")]),_c('span',{staticClass:"set-type-title-intent"},[_vm._v("关联知识库/分类/意图")]),_c('span',{staticClass:"set-type-title-handle"})])
}]

export { render, staticRenderFns }