<template>
    <div id="gr-right-content-bottom-b">
        <div
            v-if="
                activeServiceObj.WORKORDER != -1 &&
                rolePrivilegesList.indexOf('PORTAL_ENTRANCE_WORKORDER') !== -1
            "
            @click="enterModule(activeServiceObj.WORKORDER)"
            style="color: #7367f0; background: #efedff"
            class="gr-left-cbb-c gr-left-cbb-c-w"
        >
            <span class="icon">
                <i class="iconfont guoran-askservice-logo03"></i>
            </span>
            <span class="a-text">
                {{renderText('', 'WORKORDER')}}
                <!-- AskService工单系统 -->
            </span>
        </div>
        <div
            v-if="
                activeServiceObj.MANAGEMENT != -1 &&
                rolePrivilegesList.indexOf('PORTAL_ENTRANCE') !== -1
            "
            @click="enterModule(activeServiceObj.MANAGEMENT)"
            style="color: rgb(255, 196, 54); background: rgb(255, 248, 230)"
            class="gr-left-cbb-c gr-left-cbb-c-m"
        >
            <span class="icon">
                <i class="iconfont guoran-tongyichicun-guanlixitong1"></i>
            </span>
            <span class="a-text">{{$t('common.MANAGEMENT')}}</span>
        </div>
        <div
            v-if="
                activeServiceObj.IM != -1 &&
                rolePrivilegesList.indexOf('PORTAL_ENTRANCE_IM') !== -1
            "
            @click="enterModule(activeServiceObj.IM)"
            style="color: #09ddd5; background: #e1faf9"
            class="gr-left-cbb-c gr-left-cbb-c-i"
        >
            <span class="icon">
                <i class="iconfont guoran-guanlixitong1"></i>
            </span>
            <span class="a-text">
                {{ renderText('', 'IM') }}
                <!-- AskChat即时通讯IM -->
            </span>
        </div>
        <div
                v-if="activeServiceObj.KNOWLEDGE != -1 && rolePrivilegesList.indexOf('PORTAL_ENTRANCE_KNOWLEDGE') !== -1
                    "
                @click="enterModule(activeServiceObj.KNOWLEDGE)"
                style="color: #555555; background: #F8F8F8"
                class="gr-left-cbb-c gr-left-cbb-c-know"
            >
                <span class="icon">
                    <img src="../../../assets/images/knowledge.png">
                </span>
                <span class="a-text">
                    <!-- AskBot知识管理 -->
                    {{ renderText('', 'KNOWLEDGE') }}
                </span>
        </div>
        <div
            v-if="activeServiceObj.ASKAI != -1"
            @click="enterModule(activeServiceObj.ASKAI, 'ASKAI')"
            style="color: #366aff; background: #eff7ff"
            class="gr-left-cbb-c gr-left-cbb-c-askai"
        >
            <span class="icon">
                <i class="iconfont guoran-AskAI-logo-dinggao"></i>
            </span>
            <span class="a-text">
                {{renderText('', 'ASKAI')}}
            </span>
        </div>
        <div
            v-if="activeServiceObj.QUESTION != -1"
            @click="enterModule(activeServiceObj.QUESTION, 'QUESTION')"
            style="color: #366aff; background: #eff3ff"
            class="gr-left-cbb-c gr-left-cbb-c-open"
        >
                <span class="icon">
                    <img src="../../../assets/images/AskQNR.png">
                </span>
            <span class="a-text">
                {{renderText('', 'QUESTION')}}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: ["activeServiceObj", "rolePrivilegesList"],
    data() {
        return {};
    },
    methods: {
        enterModule(redirectUrl) {
            let tempwindow = window.open("_blank");
            tempwindow.location = redirectUrl;
        },
        renderText(uid, type){
            if(uid == '20281' || uid == '117431') {
                switch (type) {
                    case 'WORKORDER':
                        return this.$t('common.' + type)
                    case 'IM':
                        return this.$t('common.' + type)
                    case 'KNOWLEDGE':
                        return this.$t('common.' + type)
                    case 'ASKAI':
                        return this.$t('common.' + type)
                    case 'QUESTION':
                        return this.$t('common.' + type)
                }
            } else if(window.location.hostname.includes('ebot.isheely.com') || window.location.hostname.includes('localhost')){
                switch (type) {
                    case 'WORKORDER':
                        return 'eService'
                    case 'IM':
                        return 'eChat'
                    case 'KNOWLEDGE':
                        return 'eKMS'
                    case 'ASKAI':
                        return 'eAI'
                    case 'QUESTION':
                        return 'AskAI'
                }
            } else {
                switch (type) {
                    case 'WORKORDER':
                        return 'AskService'
                    case 'IM':
                        return 'AskChat'
                    case 'KNOWLEDGE':
                        return 'AskKMS'
                    case 'ASKAI':
                        return 'AskAI'
                    case 'QUESTION':
                        return 'AskQNR'
                }
            }
        }
    },
};
</script>
<style lang="less" scoped>
#gr-right-content-bottom-b {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    .gr-left-cbb-c-m:hover {
        border: solid 1px rgb(255, 196, 54);
    }
    .gr-left-cbb-c-w:hover {
        border: solid 1px #7367f0;
    }
    .gr-left-cbb-c-i:hover {
        border: 1px solid #09ddd5;
    }
    .gr-left-cbb-c-know:hover{
      border: 1px solid #bfbfbf;
    }
    .gr-left-cbb-c-askai:hover{
      border: 1px solid #366aff;
    }
    .gr-left-cbb-c-open:hover {
        border: 1px solid #366aff;
    }
    .gr-left-cbb-c {
        flex: 1;
        min-width: 150px;
        text-align: center;
        margin: 0 14px;
        background: #eff3ff;
        border-radius: 6px;
        height: 105px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border: 1px solid #FFFFFF;
        .icon {
            margin-top: 20px;
            i {
                font-size: 40px;
            }
            img{
              width: 37px;
              height: 37px;
            }
        }
        .a-text {
            margin-top: 6px;
            font-size: 13px;
        }
    }
}
</style>
