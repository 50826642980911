<template>
	<div class="intent-statistics">
		<el-drawer
            class="drawer-statistics"
            key="intent-statistics"
            size="600px"
            show-close
            direction="rtl"
            :visible.sync="visible"
            :close-on-press-escape="false"
            :wrapperClosable="false"
            :with-header="true">
            <div class="notice-drewer-header">
                <i class="el-icon-arrow-left" v-if="drewerType === 'question'" @click="goBack"></i>
                <div class="title">{{titles}}</div>
                <div class="close">
                    <span @click="handleClose" class="el-icon-close"></span>
                </div>
            </div>
            <div class="content">
                <el-table
                    v-if="drewerType == 'intent'"
                    :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400}"
                    cell-style="{text-align: center;cursor: pointer;}"
                    :data="tableData"
                    @cell-click="cellClick"
                >
                    <el-table-column prop="index" :label="$t('statisticsUp.questionConsultation.ranking')" width="50">
                    </el-table-column>
                    <el-table-column prop="intentName" :label="$t('statisticsUp.questionConsultation.intentName')">
                    </el-table-column>
                    <el-table-column prop="hits" width="80" :label="$t('statisticsUp.questionConsultation.consultationFrequency')">
                    </el-table-column>
                </el-table>
                <el-table
                    :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400}"
                    v-else-if="drewerType === 'question'"
                    :data="tableData2"
                >
                    <el-table-column prop="rank" :label="$t('statisticsUp.questionConsultation.ranking')" width="50">
                    </el-table-column>
                    <el-table-column prop="question" :label="$t('statisticsUp.questionConsultation.question')">
                    </el-table-column>
                    <el-table-column prop="hits" width="80" :label="$t('statisticsUp.questionConsultation.consultationFrequency')">
                    </el-table-column>
                </el-table>
                <el-table
                    :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400}"
                    v-else
                    :data="tableDataTree"
                    row-key="id"
                >
                    <el-table-column prop="name" :label="$t('statisticsUp.questionConsultation.questionOrIntent')">
                    </el-table-column>
                    <el-table-column prop="value" width="80" :label="$t('statisticsUp.questionConsultation.consultationFrequency')">
                    </el-table-column>
                    <el-table-column prop="percentage" width="80" :label="$t('statisticsUp.questionConsultation.consultationProportion')">
                       <template slot-scope="scope">
                           <span>{{ scope.row.percentage + '%' }}</span>
                       </template>
                    </el-table-column>
                </el-table>
            </div>
            
        </el-drawer>
	</div>
</template>

<script>
export default {
	data(){
		return{
            tableData2:[],
            titles:"",
		}
	},
	props:{
		visible:{
			type:Boolean,
			default:false
		},
        title:{
            type:String,
            default:""
        },
        tableData:{
            type:Array,
            default(){
                return []
            }
        },
        drewerType:{
            type:String,
            default:""
        },
        intentTopTenQuestion:{
            type:Object,
            default(){
                return {}
            }
        },
        tableDataTree:{
            type:Object,
            default(){
                return []
            }
        }
	},
    watch:{
        drewerType(n){
            console.log(n,'nnnn');
            if(n === 'intent'){
                this.titles = this.$t('statisticsUp.questionConsultation.intentConsultationProportion');
            } else if(n === 'question'){
                this.titles = this.this.$t('statisticsUp.questionConsultation.rankingConsultingQuestions');
            } else if(n === 'questionDetail'){
                this.titles = this.this.this.$t('statisticsUp.questionConsultation.questionDetail');
            }
            
        }
    },
	methods:{
        goBack(){
            this.drewerType = 'intent';
            this.titles= this.this.$t('statisticsUp.questionConsultation.intentConsultationProportion');
        },
		handleClose(){
			this.$emit('update:visible',false)
        },
        cellClick(row){
            console.log(row,'0-0-',this.intentTopTenQuestion);
            this.drewerType = 'question';
            this.titles= this.this.$t('statisticsUp.questionConsultation.rankingConsultingQuestions');
            for (let key in this.intentTopTenQuestion) {
                if (Object.hasOwnProperty.call(this.intentTopTenQuestion, key)) {
                    console.log(key,row.intentId);
                    let element = this.intentTopTenQuestion[key];
                    if(key == row.intentId){
                        this.tableData2 = element
                    }
                }
            }
            this.$forceUpdate()
            // console.log(this.tableData2,'tableDataTree');
        }
	}
}
</script>

<style lang="less" scoped>
.intent-statistics{
	.drawer-statistics{
		/deep/ .el-drawer{
			.el-drawer__header{
				height: 0;
				padding: 0;
				margin: 0;
				overflow: hidden; 
                
			}
            .notice-drewer-header {
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex: none;
                height: 45px;
                background: #F6F8FD;
                padding: 0 20px;
                margin-bottom: 20px;
                .el-icon-arrow-left{
                    cursor: pointer;
                    margin-right: 6px;
                }
                .title {
                    flex: none;
                    font-size: 16px;
                }
                .notice-type-box {
                    width: 200px;
                    flex: none;
                }
                .close {
                    flex: auto;
                    text-align: right;
                    font-size: 14px;
                    cursor: pointer;
                }
            }
            .content{
                padding: 0 10px;
                .el-table{
                    max-height: calc(100vh - 90px);
                    overflow-y: auto;
                }
            }
			
		}
	}
}

</style>