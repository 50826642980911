<template>
    <popup v-if="noticePopupVisiable" @closeEvent="sure">
        <div slot="popup-name">{{ $t('common.updateNotice') }}</div>
        <div slot="popup-con">
            <div class="content">
                <div class="notice_row" v-for="(notice, noticeKey) in noticeMap" :key="noticeKey">
                    <div class="notice_title">
                        {{ notice.updateNoticeTitle }}
                    </div>
                    <div class="notice_cot" v-html="notice.updateNoticeDescription">
                    </div>
                    <div class="notice_label">
                        AskBot团队
                    </div>
                    <div class="notice_time">
                        {{ new Date(notice.createTime).Format("yyyy-MM-dd hh:mm:ss")  }}
                    </div>
                </div>
            </div>
        </div>
        <div slot="dialog-footer">
            <!-- <el-button @click="closeEmit" plain class="cancel-btn">{{ $t('common.cancel') }}</el-button> -->
            <el-button type="primary" @click="sure" class="confirm-btn">{{ $t('common.gotIt') }}</el-button>
        </div>
    </popup>
</template>

<script>
import popup from './popup.vue'
export default {
    components: { popup },
    data () {
        return {
            noticePopupVisiable: false,
            noticeMap: [],
            noticeIds:[]
        }
    },
    mounted () {
        this.getStrongNotice()
    },
    methods: {
        getStrongNotice () {
            let url = "/portal-api/userNotice/?userId=" + localStorage.getItem("_uid") + '&systemName=' + process.env.VUE_APP_SYSTEM_NAME
            this.FetchGet(url).then(res => {
                if (res.code == '0' && res.data && Object.keys(res.data).length != 0) {
                    this.noticePopupVisiable = true
                    // this.noticeMap = res.data
                    let obj = res.data
                    let arr = []
                    for (const key in obj) {
                        let notice = obj[key]
                        let des = this.replaceIframeWithVideo(notice.updateNoticeDescription)
                        notice.updateNoticeDescription = des
                        notice.updateNoticeTitle = notice.updateNoticeTitle.replace("*+@@+*", '')
                        // notice.key = key
                        this.noticeIds.push(key)
                        arr.push(notice)
                    }
                    arr.sort((a, b) => new Date(b.createTime).getTime()  - new Date(a.createTime).getTime());
                    this.noticeMap = arr
                    // console.log(arr,'arr');
                    // arr.forEach(item => {
                    //     this.$set(this.noticeMap, item.key, item)
                    // })
                    // console.log(this.noticeMap);
                }
            })
        },
        replaceIframeWithVideo (htmlString) {
            // 解析 HTML 字符串为 DOM
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');

            // 查找所有的 iframe 元素
            const iframes = doc.querySelectorAll('iframe');

            iframes.forEach(iframe => {
                // 创建一个新的 video 元素
                const video = document.createElement('video');
                video.setAttribute('width', iframe.getAttribute('width'));
                video.setAttribute('height', "auto");
                video.setAttribute('controls', 'controls'); // 使 video 支持播放控制
                // 创建 source 元素并设置 src 属性
                const source = document.createElement('source');
                source.setAttribute('src', iframe.getAttribute('customaryurl'));
                source.setAttribute('type', 'video/mp4'); // 根据实际视频类型设置
                // 将 source 添加到 video 元素中
                video.appendChild(source);
                // 替换 iframe 为 video
                iframe.parentNode.replaceChild(video, iframe);
            });
            // 返回修改后的 HTML 字符串
            return doc.documentElement.outerHTML;
        },
        closeEmit () {
            this.noticePopupVisiable = false
        },
        sure () {
            // let ids = []
            // for (const key in this.noticeMap) {
            //     ids.push(key)
            // }
            // ids.forEach(id => {
            //     this.$http.delete("/portal-api/userNotice/?userNoticeId=" + id)
            // })

            // 执行的是后端对这个员工记录通知记录的批量删除逻辑
            this.$http.put("/portal-api/userNotice/?userNoticeIds=" + this.noticeIds.join())
            this.noticeMap = []
            this.noticePopupVisiable = false
        }
    }
}
</script>

<style lang="less" scoped>
.content {
    height: auto;
    text-align: left;
    .notice_row {
        width: 100%;
        margin-bottom: 60px;
        .notice_title {
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 12px;
        }
        .notice_label, .notice_time {
            margin-bottom: 10px;
            text-align: right;
        }
        .notice_cot {
            line-height: 26px;
            /deep/ video {
                margin: 12px 0;
            }
        }
    }
}
</style>