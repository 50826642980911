<template>
    <div id="top-ten">
        <!-- <el-table
            size="mini"
            cell-style="{text-align: center;}"
            v-loading="loading"
            v-if="tableType == 'intent'"
            :data="tableData"
        >
            <el-table-column prop="index" label="排名" width="50">
            </el-table-column>
            <el-table-column prop="intentName" label="意图名称">
            </el-table-column>
            <el-table-column prop="hits" width="80" label="咨询频次">
            </el-table-column>
        </el-table>
        <el-table
            size="mini"
            v-loading="loading"
            v-if="tableType == 'qw'"
            :data="tableData"
        >
            <el-table-column prop="index" label="排名" width="50">
            </el-table-column>
            <el-table-column prop="question" label="问题">
            </el-table-column>
            <el-table-column prop="hits" width="80" label="咨询频次">
            </el-table-column>
        </el-table> -->
        <template v-if="tableData && tableData.length > 0">
            <div :class="['item',tableType == 'intent' ? 'intent' : '']" v-for="(item,index) in tableData" :key="index" @click="lookItem(item)">
                <div class="left">
                    <div :class="{'num':true,'first':index===0,'second':index===1,'third':index===2}">{{index+1}}</div>
                    <el-tooltip popper-class="top-ten-tooltip" class="one-column-ellipsis" effect="dark" :content="tableType == 'intent' ? item.intentName : item.question" placement="top-start" :open-delay="500">
                        <div slot="content" class="tooltip-content">{{tableType == 'intent' ? item.intentName : item.question}}</div>
                        <div class="name one-column-ellipsis">{{tableType == 'intent' ? item.intentName : item.question}}</div>
                    </el-tooltip>
                </div>
                    <!-- <i class="iconfont guoran-tongyichicun-zixuncishu"></i> -->
                <span class="hits">{{item.hits}}{{$t('statisticsUp.second')}}</span>
            </div>
        </template>
        <noDataEcharts v-else></noDataEcharts>
        <intentQuestionDrewer 
            :visible.sync="visible" 
            :data="activeData"
            :tableData="tableData"
            :drewerType.sync="drewerType"
            :title="'咨询问题排名'"
            :intentTopTenQuestion="intentTopTenQuestion">
        </intentQuestionDrewer>
    </div>
</template>
<script>
import noDataEcharts from './noDataEcharts.vue';
import intentQuestionDrewer from './intentQuestionDrewer.vue'
export default {
    name: "top-ten",
    props: ["tableData", "tableType", "loading","intentTopTenQuestion"],
    components:{noDataEcharts,intentQuestionDrewer},
    data() {
        return {
            visible:false,
            activeData:{},
            drewerType:""
        };
    },
    methods: {
        lookItem(item){
            console.log(item);
            if(this.tableType === 'qw')return;
            this.activeData = item;
            this.drewerType = 'intent'
            this.visible = true;
        }
    },
    mounted() {},
};
</script>
<style lang="less">
.top-ten-tooltip{
    max-width: 45vw;
}
</style>
<style lang="less" scoped>
#top-ten {
    height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
    .item{
        display: flex;
        height: 45px;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        .left{
            display: flex;
            align-items: center;
            .num{
                height: 18px;
                padding: 0 6px;
                background: #FFFFFF;
                border: 1px solid #366AFF;
                border-radius: 5px;
                font-size: 12px;
                color: #366AFF;
                margin-right: 34px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.first{
                    border-color: #FF9555;
                    color: #FF9555;
                }
                &.second{
                    border-color: #09DDD5;
                    color: #09DDD5;
                }
                &.third{
                    border-color: #766BF0;
                    color: #766BF0;
                }

            }
            .name{
                color: #000000;
                font-size: 14px;
            }
        }
        .hits{
            white-space: nowrap;
            margin-left: 20px;
        }
        .iconfont{
            color: #A0BDFF;
            margin-right: 4px;
        }
        &.intent{
            cursor: pointer;
        }
        &:nth-child(odd){
            background-color: #FAFBFD;
        }
        &:nth-child(even){
            background-color: #fff;
        }
    }
}
</style>