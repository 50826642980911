import _ from "lodash";

const nodeListToIntents = (nodeList, nodeTree) => {
    let intentArr = [];
    nodeList.forEach((nodeObj) => {
        nodeObj.nodeId.forEach((nodeCell) => {
            if (nodeObj.nodeType == "BOT") {
                nodeTree.forEach((botCell) => {
                    if (botCell && botCell.id == nodeCell) {
                        botCell.children.forEach((skillCell) => {
                            if (skillCell && skillCell.children) {
                                skillCell.children.forEach(
                                    (typeCell) => {
                                        if (
                                            typeCell &&
                                            typeCell.children
                                        ) {
                                            typeCell.children.forEach(
                                                (intentCell) => {
                                                    intentArr = [
                                                        ...new Set([
                                                            ...intentArr,
                                                            ...[
                                                                intentCell.id,
                                                            ],
                                                        ]),
                                                    ];
                                                }
                                            );
                                        }
                                    }
                                );
                            }
                        });
                    }
                });
            }
            if (nodeObj.nodeType == "SKILL") {
                nodeTree.forEach((botCell) => {
                    botCell.children.forEach((skillCell) => {
                        if (
                            skillCell &&
                            skillCell.children &&
                            skillCell.id == nodeCell
                        ) {
                            skillCell.children.forEach((typeCell) => {
                                if (typeCell && typeCell.children) {
                                    typeCell.children.forEach(
                                        (intentCell) => {
                                            intentArr = [
                                                ...new Set([
                                                    ...intentArr,
                                                    ...[intentCell.id],
                                                ]),
                                            ];
                                        }
                                    );
                                }
                            });
                        }
                    });
                });
            }
            if (nodeObj.nodeType == "INTENT_TYPE") {
                nodeTree.forEach((botCell) => {
                    botCell.children.forEach((skillCell) => {
                        if (skillCell && skillCell.children) {
                            skillCell.children.forEach((typeCell) => {
                                if (
                                    typeCell &&
                                    typeCell.children &&
                                    typeCell.id == nodeCell
                                ) {
                                    typeCell.children.forEach(
                                        (intentCell) => {
                                            intentArr = [
                                                ...new Set([
                                                    ...intentArr,
                                                    ...[intentCell.id],
                                                ]),
                                            ];
                                        }
                                    );
                                }
                            });
                        }
                    });
                });
            }
            if (nodeObj.nodeType == "INTENT") {
                nodeTree.forEach((botCell) => {
                    botCell.children.forEach((skillCell) => {
                        if (skillCell && skillCell.children) {
                            skillCell.children.forEach((typeCell) => {
                                if (typeCell && typeCell.children) {
                                    typeCell.children.forEach(
                                        (intentCell) => {
                                            if (
                                                intentCell.id ==
                                                nodeCell
                                            ) {
                                                intentArr = [
                                                    ...new Set([
                                                        ...intentArr,
                                                        ...[
                                                            intentCell.id,
                                                        ],
                                                    ]),
                                                ];
                                            }
                                        }
                                    );
                                }
                            });
                        }
                    });
                });
            }
        });
    });

    return intentArr;
}
const convertToStringArray = (arr) => {
    return arr.flatMap((item) =>
        item.nodeId.map((id) => `${item.nodeType}~${id}`)
    );
}

const toIntentTree4Radio = (arr) => {
    let nodeTree = _.cloneDeep(arr);
    nodeTree.forEach((botCell) => {
        botCell.id = "BOT~" + botCell.id;
        if (botCell && botCell.children) {
            botCell.children.forEach((skillCell) => {
                skillCell.id = "SKILL~" + skillCell.id;
                if (skillCell && skillCell.children) {
                    skillCell.children.forEach(
                        (typeCell) => {
                            typeCell.id =
                                "INTENT_TYPE~" +
                                typeCell.id;
                            if (
                                typeCell &&
                                typeCell.children
                            ) {
                                typeCell.children.forEach(
                                    (intentCell) => {
                                        intentCell.id =
                                            "INTENT~" +
                                            intentCell.id;
                                    }
                                );
                            }
                        }
                    );
                }
            });
        }
    });
    return nodeTree;
}

const formatSaveConfig = (tampList, nodeTree) => {
    tampList.forEach((item, itemIndex) => {
        item.nodeList = [
            {
                nodeId: [],
                nodeType: "BOT",
            },
            {
                nodeId: [],
                nodeType: "SKILL",
            },
            {
                nodeId: [],
                nodeType: "INTENT_TYPE",
            },
            {
                nodeId: [],
                nodeType: "INTENT",
            },
        ];

        item.intentIds.forEach((cell) => {
            let cellArr = cell.split("~");

            if (cellArr[0] == "BOT") {
                item.nodeList[0].nodeId.push(cellArr[1]);
            }
            if (cellArr[0] == "SKILL") {
                item.nodeList[1].nodeId.push(cellArr[1]);
            }
            if (cellArr[0] == "INTENT_TYPE") {
                item.nodeList[2].nodeId.push(cellArr[1]);
            }
            if (cellArr[0] == "INTENT") {
                item.nodeList[3].nodeId.push(cellArr[1]);
            }
        });
        item.nodeList = item.nodeList.filter(
            (nodeObj) => nodeObj.nodeId.length > 0
        );

        tampList[itemIndex].intentIds = nodeListToIntents(
            item.nodeList, nodeTree
        );
        tampList.forEach((item) => {
            delete item.selectAll;
        });
    });
    return tampList;
}

export { nodeListToIntents, convertToStringArray, toIntentTree4Radio, formatSaveConfig }