<template>
    <div id="set-type">
        <div class="set-type-title">
            <span class="set-type-title-type">问题类型</span>
            <span class="set-type-title-intent">关联知识库/分类/意图</span>
            <span class="set-type-title-handle"></span>
        </div>
        <div class="set-type-list">
            <div
                v-for="(cell, index) in list"
                :key="index"
                class="set-type-list-cell"
            >
                <div class="set-type-title-type">
                    <el-input
                        size="mini"
                        v-model="cell.name"
                        maxlength="10"
                        placeholder="请输入问题类型"
                    ></el-input>
                </div>
                <div class="set-type-title-intent">
                    <el-cascader
                        v-model="cell.intentIds"
                        size="mini"
                        :rows="3"
                        :key="'list_' + index"
                        :options="intentTree4Radio"
                        :show-all-levels="false"
                        :collapse-tags="true"
                        placeholder="请选择意图"
                        :props="{
                            emitPath: false,
                            label: 'name',
                            value: 'id',
                            rows: '3',
                            multiple: true,
                        }"
                    ></el-cascader>
                </div>
                <div v-if="list.length != 1" class="set-type-title-handle">
                    <span
                        @click="deleteSetType(index)"
                        class="el-icon-delete"
                    ></span>
                </div>
            </div>
        </div>
        <div class="add-set-type">
            <el-button @click="addType" size="mini" type="primary" round
                >添加问题类型</el-button
            >
        </div>
        <div class="set-type-dialog-footer">
            <el-button @click="cancelSaveSetType" size="mini">取 消</el-button>
            <el-button size="mini" type="primary" @click="saveSetType"
                >确 定</el-button
            >
        </div>
    </div>
</template>
<script>
export default {
    name: "set-type",
    props: ["scope", "scopeType"],
    data() {
        return {
            nodeTree: [],
            intentTree4Radio: [],
            list: [
                {
                    ext: {},
                    intentIds: [],
                    name: "",
                },
            ],
        };
    },
    methods: {
        saveSetType() {
            let arr = [...new Set(this.list.map(item => item.name))];
            if (arr.length != this.list.length) {
                this.$message.error("问题类型名称重复，请修改后保存！");
            } else {
                this.$emit("saveSetType", this.list);
            }
        },
        cancelSaveSetType() {
            this.$emit("cancelSaveSetType");
        },
        // 获取当前机器人下知识库意图列表
        getIntentTreeByScope() {
            this.FetchGet(
                this.requestUrl.statistics.getIntentTreeByScope + this.scope,
                { scopeType: this.scopeType }
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.nodeTree = [...res.data];
                        let nodeTree = JSON.parse(JSON.stringify(res.data));
                        // for (let i = nodeTree.length - 1; i >= 0; i--) {
                        //     for (
                        //         let j = nodeTree[i].children.length - 1;
                        //         j >= 0;
                        //         j--
                        //     ) {
                        //         for (
                        //             let k =
                        //                 nodeTree[i].children[j].children
                        //                     .length - 1;
                        //             k >= 0;
                        //             k--
                        //         ) {
                        //             for (
                        //                 let l =
                        //                     nodeTree[i].children[j].children[k].children
                        //                         .length - 1;
                        //                 l >= 0;
                        //                 l--
                        //             ) {
                        //                 let intent =
                        //                     nodeTree[i].children[j].children[k].children[l];
                        //                 nodeTree[i].children[j].children[k].children[l] = {
                        //                     id: intent.id,
                        //                     name: intent.name,
                        //                 };
                        //             }
                        //         }
                        //     }
                        //     this.intentTree4Radio = nodeTree;
                        //     console.log(this.intentTree4Radio);
                        // }
                        this.intentTree4Radio = nodeTree;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取当前机器人设置问题类型列表
        getSetTypeList() {
            this.FetchGet(
                this.requestUrl.statistics.getConfigQuestionType + this.scope,
                {}
            )
                .then((res) => {
                    if (res.code === "0") {
                        this.list = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        addType() {
            this.list.push({
                ext: {},
                intentIds: [],
                name: "",
            });
        },
        deleteSetType(index) {
            this.list.splice(index, 1);
        },
    },
    mounted() {
        this.getIntentTreeByScope();
        this.getSetTypeList();
    },
};
</script>
<style lang="less" scoped>
#set-type {
    .set-type-dialog-footer {
        margin-top: 20px;
        text-align: right;
    }
    .add-set-type {
        margin: 12px 0;
    }
    .set-type-list {
        margin-bottom: 4px;
    }
    .set-type-title,
    .set-type-list-cell {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 6px;
        .set-type-title-type {
            flex: none;
            width: 120px;
            text-align: left;
            margin-right: 6px;
        }
        .set-type-title-intent {
            flex: auto;
            text-align: left;
            .el-cascader {
                width: 100%;
            }
        }
        .set-type-title-handle {
            flex: none;
            width: 30px;
            text-align: center;
            line-height: 28px;
            color: red;
            cursor: pointer;
        }
    }
}
</style>