<template>
    <div class="main">
        <div id="content">
            <div id="nav">
                <div :class="['collapse-handle',uid == 20281 || uid == 117431? 'no-bgc' : '']">
                    <img v-if="uid == 20281 || uid == 117431" src="../../assets/images/jdLogo.png" alt="" srcset="">
                    <svg
                        v-else
                        t="1648199555802"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="77606"
                        width="37"
                        height="37"
                    >
                        <path
                            d="M650.752 18.944H373.248c-174.08 0-315.392 141.312-315.392 315.392v276.992c0 174.08 141.312 315.392 315.392 315.392h240.128l146.432 81.92c23.552 13.312 52.224-3.584 52.736-30.72v-96.256c91.648-55.296 153.6-155.648 153.6-270.336V334.336c0-174.592-141.312-315.392-315.392-315.392zM512 754.176c-137.728 0-249.856-103.936-256.512-235.008 0-1.024-0.512-2.048-0.512-3.072V225.28c0-14.848 12.288-27.136 27.136-27.136h130.56c14.848 0 27.136 12.288 27.136 27.136v44.544c23.04-6.656 47.104-10.24 72.192-10.24 142.336 0 257.536 110.592 257.536 246.784 0 137.216-115.2 247.808-257.536 247.808z"
                            fill="#ffffff"
                            p-id="77607"
                        ></path>
                        <path
                            d="M627.2 409.6h-230.4c-45.568 0-82.432 36.864-82.432 82.432 0 45.568 36.864 82.432 82.432 82.432h229.888c45.568 0 82.432-36.864 82.432-82.432 0-45.568-36.864-82.432-81.92-82.432z m-223.744 128.512c-25.6 0-46.08-20.48-46.08-46.08s20.48-46.08 46.08-46.08 46.08 20.48 46.08 46.08-20.48 46.08-46.08 46.08z m217.088 0c-25.6 0-46.08-20.48-46.08-46.08s20.48-46.08 46.08-46.08 46.08 20.48 46.08 46.08-20.992 46.08-46.08 46.08z"
                            fill="#ffffff"
                            p-id="77608"
                        ></path>
                    </svg>
                </div>

                <el-menu
                    :default-active="$route.path"
                    class="el-menu-vertical-demo"
                    background-color="#1D2E54"
                    text-color="#ffffff"
                    active-text-color="#fff"
                    :collapse="isCollapse"
                >
                    <template v-for="item in navList">
                        <el-menu-item
                            :index="item.index"
                            :key="item.name"
                            v-if="!item.isHide && item.type === 1"
                            :class="item.isActive ? 'active-item' : ''"
                            @click="gotoLink(item.link)"
                        >
                            <em :class="item.icon"></em>
                            <span slot="title">{{ item.name }}</span>
                        </el-menu-item>
                        <el-submenu
                            :index="item.index"
                            :key="item.name"
                            v-if="!item.isHide && item.type === 2"
                        >
                            <template slot="title">
                                <em class="el-icon-location"></em>
                                <span slot="title">{{ item.name }}</span>
                            </template>
                            <template v-for="cell in item.children">
                                <el-menu-item
                                    :index="cell.index"
                                    :key="cell.name"
                                    :class="item.isActive ? 'active-item' : ''"
                                    @click="gotoLink(cell.link)"
                                    >{{ cell.name }}</el-menu-item
                                >
                            </template>
                        </el-submenu>
                    </template>
                </el-menu>

                <div class="switch-system">
                    <svg
                        @click="changeSwitchDia"
                        v-if="
                            !(
                                activeServiceObj.WORKORDER == -1 &&
                                activeServiceObj.MANAGEMENT == -1 &&
                                activeServiceObj.IM == -1 &&
                                activeServiceObj.KNOWLEDGE == -1
                            )
                        "
                    t="1648203029511"
                    class="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="211692"
                    width="24"
                    height="24"
                >
                    <path
                        d="M969.216 228.352c18.944 0 34.304 15.36 34.304 34.304s-14.848 33.792-33.792 34.304h-99.84c84.992 69.632 125.952 161.28 120.832 262.144-5.632 105.984-62.976 213.504-153.6 294.912-14.336 12.8-35.84 11.264-48.64-2.56-12.8-14.336-11.264-35.84 2.56-48.64 77.824-69.632 125.952-160.256 130.56-246.784 4.608-82.944-30.72-157.696-107.52-214.528v110.08c0 18.944-14.848 33.792-33.792 34.304h-1.024c-18.944 0-33.792-14.848-34.304-33.792V263.168c0-18.944 14.848-33.792 33.792-34.304h1.024l189.44-0.512zM275.456 190.976c9.728 15.872 5.12 36.864-10.752 47.104l-0.512 0.512C168.448 296.96 124.416 378.88 129.536 471.04c3.584 69.12 34.816 140.8 87.04 202.24v-113.664c0-18.944 14.848-33.792 33.792-34.304h1.024c18.944 0 33.792 14.848 34.304 33.792v189.952c0 18.944-14.848 33.792-33.792 34.304H61.952c-18.944 0-34.304-15.36-34.304-34.304s14.848-33.792 33.792-34.304h99.328c-59.392-71.68-95.232-156.16-99.84-239.104-6.144-117.76 50.688-223.232 167.424-295.424 15.872-10.752 37.376-5.632 47.104 10.752z"
                        fill="#458FFF"
                        p-id="211693"
                    ></path>
                    <path
                        d="M549.376 17.408c52.224 0 94.72 42.496 94.72 94.72v62.976c0 52.224-42.496 94.72-94.72 94.72H486.4c-52.224 0-94.72-42.496-94.72-94.72V112.128c0-52.224 42.496-94.72 94.72-94.72h62.976z"
                        fill="#FF9555"
                        p-id="211694"
                    ></path>
                    <path
                        d="M549.376 742.912c52.224 0 94.72 42.496 94.72 94.72v61.44c0 52.224-42.496 94.72-94.72 94.72H486.4c-52.224 0-94.72-42.496-94.72-94.72v-61.44c0-52.224 42.496-94.72 94.72-94.72h62.976z"
                        fill="#00C2BB"
                        p-id="211695"
                    ></path>
                    </svg>
                    <transition name="fade">
                        <div v-if="showSwitch" class="switch-box">
                            <div class="tr-w">
                                <svg
                                    t="1653379591086"
                                    class="icon"
                                    viewBox="0 0 1024 1024"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    p-id="2979"
                                    width="16"
                                    height="16"
                                >
                                    <path
                                        d="M325.456896 862.27968"
                                        p-id="2980"
                                        fill="#ffffff"
                                    ></path>
                                    <path
                                        d="M882.05824 862.27968"
                                        p-id="2981"
                                        fill="#ffffff"
                                    ></path>
                                    <path
                                        d="M236.027904 877.161472"
                                        p-id="2982"
                                        fill="#ffffff"
                                    ></path>
                                    <path
                                        d="M817.898496 962.556928 817.898496 63.654912 207.63648 513.099776Z"
                                        p-id="2983"
                                        fill="#ffffff"
                                    ></path>
                                    <path
                                        d="M960.132096 877.161472"
                                        p-id="2984"
                                        fill="#ffffff"
                                    ></path>
                                </svg>
                            </div>
                            <span
                                @click="showSwitch = false"
                                class="switch-box-close"
                            >
                                <i class="arsenal_icon arsenalcuo1"></i>
                            </span>
                            <span class="switch-box-title">{{$t('common.changeSys')}}</span>
                            <div class="switch-box-content">
                                <ActiveService
                                    :activeServiceObj="activeServiceObj"
                                    :rolePrivilegesList="rolePrivilegesList"
                                />
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div id="main">
                <div class="main-container">
                    <router-view />
                </div>
                <Test
                    :testDrawerVisibleP="testDrawerVisibleP"
                    @changeTestContentVisible="changeTestContentVisible"
                ></Test>
                 <botHeathlyDrawer 
                    :botHeathlyDrawerVisible.sync="botHeathlyDrawerVisible"
                    @addIntent="addIntent"></botHeathlyDrawer>
                <!-- <div
                    v-show="!testDrawerVisibleP && isActive != 'personal'"
                    class="test-btn-new"
                    @click="showTest"
                >
                    <span class="test-btn-icon-outer">
                        <i class="iconfont guoran-tongyichicun-ceshi"></i>
                    </span>
                    <span class="test-btn-text-outer"> 测试一下 </span>
                </div> -->
                 <div
                    v-show="!testDrawerVisibleP && isActive != 'personal' && !isBotIntent"
                    class="test-btn-news"
                    @click="showTest"
                >
                    <span class="test-btn-icon-outers">
                        <i class="iconfont guoran-tongyichicun-ceshi"></i>
                    </span>
                    <span class="test-btn-text-outers">测试</span>
                </div>
            </div>
        </div>
        <systemUpdateNotice ref="systemUpdateNotice"></systemUpdateNotice>
    </div>
</template>
<script>
import { redirectUri } from "@/const/redirectUri";
let that;
import botHeathlyDrawer from '../botIndex/component/botHeathlyDrawer.vue'
import Test from "./../test/Test";
// import helpVue from "../help/help.vue";
// import Dialogues from "../dialogues/dialogues.vue";
import Statistics from "../statistics/statistics.vue";
import agentConfig from "../fissionMarketing/utils/agentConfig";
import ActiveService from "./components/ActiveService.vue";
import systemUpdateNotice from "../../components/systemUpdateNotice.vue";
export default {
    data() {
        return {
            showSwitch: false,
            testDrawerVisibleP: false,
            testDrawerVisibleS: false,
            isCollapse: true,
            userInfo: {},
            helpIsActive: false,
            currentPath: "",
            isActive: "",
            defaultActive: 1,
            navList: [
                {
                    type: 1,
                    name: this.$t('botIndex.title'),
                    link: "botIndex",
                    index: "1",
                    icon: "iconfont guoran-tongyichicun-jiqirentouxiang",
                    isHide: !this.RP_Visible("OPEN_BOT"),
                    isActive: true,
                },
                {
                    type: 1,
                    name: this.$t('smartDocumentExtract.title'),
                    link: "smartDocumentExtract",
                    index: "2",
                    icon: "iconfont guoran-zhinengwendangchouqu",
                    isHide: !this.RP_Visible("DOCUMENT_EXTRACTION"),
                    isActive: true,
                },
                {
                    type: 1,
                    name: this.$t('entityManagement.title'),
                    index: "3",
                    link: "entityManagement",
                    icon: "iconfont guoran-tongyichicun-shitiguanli",
                    isHide: !this.RP_Visible("OPEN_ENTITY"),
                    isActive: false,
                },
                {
                    type: 1,
                    name: this.$t('webHook.title'),
                    index: "4",
                    link: "webhook",
                    icon: "iconfont guoran-tongyichicun-jiekouduijie",
                    isHide: !this.RP_Visible("OPEN_INTERFACE"),
                    isActive: false,
                },
                {
                    type: 1,
                    name: this.$t('trainList.title'),
                    index: "5",
                    link: "trainList",
                    icon: "iconfont guoran-tongyichicun-jiqixuexi",
                    isHide: !this.RP_Visible("OPEN_ML"),
                    isActive: false,
                },
                {
                    type: 1,
                    name: this.$t('dialogues.headerName'),
                    index: "6",
                    link: "dialogues",
                    icon: "iconfont guoran-tongyichicun-duihuajilu",
                    isHide: !this.RP_Visible("OPEN_RECORD"),
                    isActive: false,
                },
                {
                    type: 1,
                    name: this.$t('statisticsUp.headerName'),
                    index: "7",
                    link: "statistics",
                    icon: "iconfont guoran-tongyichicun-tongjifenxi",
                    isHide: !this.RP_Visible("OPEN_STATISTICS_VIEW"),
                    isActive: false,
                },
                {
                    type: 1,
                    name: this.$t('botLink.title'),
                    index: "8",
                    link: "link",
                    icon: "iconfont guoran-tongyichicun-qudaojieru",
                    isHide: !this.RP_Visible("OPEN_ACCESS"),
                },
                {
                    type: 1,
                    name: this.$t('massSend.headerName'),
                    index: "9",
                    link: "massSend",
                    icon: "iconfont guoran-tongyichicun-renwuguanli",
                    isHide: !this.RP_Visible("OPEN_TASK"),
                },
                {
                    type: 1,
                    name: this.$t('materialSelection.title'),
                    index: "10",
                    link: "material",
                    icon: "iconfont guoran-tongyichicun-sucaizhongxin",
                    isHide: !this.RP_Visible("OPEN_TASK"),
                },
                /* {
					type: 1,
					name: "任务管理",
					index: "8",
					link: "massSend",
					icon: "arsenal_icon arsenalzidongbangding",
					isHide: false
					},
					{
					type: 1,
					name: "素材中心",
					index: "9",
					link: "material",
					icon: "arsenal_icon arsenalsucai-outline",
					isHide: false
					},*/
                // {
                //   type: 1,
                //   name: "账号管理",
                //   index: "7",
                //   link: "accountManage",
                //   icon: "arsenal_icon arsenalyonghuguanli",
                //   isHide: false,
                //   isActive: false
                // },

                //{
                //    type: 1,
                //    name: "客服工作台",
                //    index: "8",
                //    link: "instantMessage",
                //    icon: "arsenal_icon arsenalkefu",
                //    isHide: false
                //},
                //{
                //    type: 1,
                //    name: "流程",
                //    index: "9",
                //    link: "chatFlow",
                //    icon: "arsenal_icon arsenalkefu",
                //    isHide: false
                //}
            ],
            locationUrl: "",
            showBusiness: false,
            roles: "",
            isHides: true,
            mainId: "",
            activeServiceObj: {
                WORKORDER: -1,
                IM: -1,
                MANAGEMENT: -1,
                KNOWLEDGE:-1,
                ASKAI:-1,
                QUESTION: -1
            },
            rolePrivilegesList: [],
            isBotIntent:false,
            botHeathlyDrawerVisible:false,
            uid:null
        };
    },
    components: {
        Test,
        Statistics,
        ActiveService,
        botHeathlyDrawer,
        systemUpdateNotice
    },
    computed: {
        roleVisbled: function () {
            let flag = 0;
            if (
                this.RP_Visible("PORTAL_ENTRANCE_OPEN") ||
                this.RP_Visible("OPEN")
            ) {
                flag += 1;
            }
            if (this.RP_Visible("PORTAL_ENTRANCE_IM")) {
                flag += 1;
            }
            if (this.RP_Visible("PORTAL_MANAGEMENT")) {
                flag += 1;
            }
            if (this.RP_Visible("PORTAL_ENTRANCE_WORKORDER")) {
                flag += 1;
            }
            if (flag >= 2) return true;
        },
    },
    watch: {
        "$route.path": function (to, oldVal) {
            console.log(to, "to-------");
            this.changeActive(to);
            // this.showIsActive(to);
        },
        "$route":function (to, oldVal) {
            console.log(to, "to22222-------");
        },

        //添加CNZZ统计
        $route: {
            handler(to, from) {
                if(to.name === 'botIntent'){
                    this.isBotIntent = true;
                } else {
                    this.isBotIntent = false;
                }
                this.isActive = to.name;
                setTimeout(() => {
                    //避免首次获取不到window._czc
                    if (window._czc) {
                        let location = window.location;
                        let contentUrl = location.pathname + location.hash;
                        let refererUrl = "/";
                        // 用于发送某个URL的PV统计请求，
                        window._czc.push([
                            "_trackPageview",
                            contentUrl,
                            refererUrl,
                        ]);
                    }
                }, 300);
            },
            immediate: true, // 首次进入页面即执行
        },
    },
    beforeCreate() {
        that = this;
        console.log('我刷新了--------------------',this.uid);
        this.$forceUpdate()
        sessionStorage.setItem('isOpenTestDrawer',false);
        sessionStorage.setItem('isOpenHeathlyDrawer',false);
        this.$eventBus.$emit("test-window-visible-intent", false);
        this.$eventBus.$emit("bot-heathly-visible", {
            flag:false,
            apiKey:'',
            botId:''
        });

    },
    created(){
        this.uid = localStorage.getItem('_uid');
        that.navList.forEach(item => {
          item.name = that.$t('common.' + item.link)
        })
    },
    beforeMount() {
        this.getRole();
        if (localStorage.getItem("roleId") !== null) {
            that.roles = localStorage.getItem("roleId");
        }
    },
    mounted() {
        sessionStorage.setItem('isOpenTestDrawer',false);
        sessionStorage.setItem('isOpenHeathlyDrawer',false);
        this.getModule();
        this.rolePrivilegesList =
            JSON.parse(localStorage.getItem("rolePrivileges")) || [];
        this.changeActive(this.$route.path);
        this.mainId = localStorage.getItem("_mainId");
        // 创建cnzz统计js
        const script = document.createElement("script");
        script.src = `https://s95.cnzz.com/z_stat.php?id=${1278715588}&web_id=${1278715588}`;
        script.language = "JavaScript";
        document.body.appendChild(script);
        
     
        this.getWechatCorp();

        // window.__be = window.__be || {};
        // window.__be.id = "1135";
        // window.__be.apiKey = "1c8f044e17f74258ac430259e2ef12ee";
        // window.__be.secret = "4d6a67784e6a55314e7a6b334f4449314f544d78";
        // (function () {    
        // var be = document.createElement('script');    
        // be.type = 'text/javascript';    
        // be.async = true;    
        // be.src = ('https://test.open.askbot.cn/ref.js');    
        // var s = document.getElementsByTagName('script')[0];    
        // s.parentNode.insertBefore(be, s)

        // //创建截图的插件引入标签  script
        // var scripts = document.createElement('script');    
        // scripts.type = 'text/javascript';    
        // scripts.async = true;    
        // scripts.src = ('https://test.open.askbot.cn/js-web-screen-shot/screenShotPlugin.umd.js');    
        // var s = document.getElementsByTagName('script')[1];    
        // s.parentNode.insertBefore(scripts, s);
        // })();
        this.$eventBus.$on("test-window-visible-intent", (visible) => {
            if(visible){
                this.showTest();
                // 隐藏健康度窗口
                this.$eventBus.$emit("bot-heathly-visible", false);
                sessionStorage.setItem('isOpenHeathlyDrawer',false);
            } else {
                this.isBotIntent = false;
                this.changeTestContentVisible(visible);
            }
        });
        this.$eventBus.$on("bot-heathly-visible", (data) => {
            this.botHeathlyDrawerVisible = data.flag;
            if(data.flag){
                sessionStorage.setItem('isOpenHeathlyDrawer',true);
                // 隐藏测试窗
                this.$eventBus.$emit("test-window-visible-intent", false);
                // this.isBotIntent = false;
                // this.changeTestContentVisible(false);
                if(data.nowOptimize === 1){

                }
                if(!this.testDrawerVisibleP && this.isActive != 'personal' && !this.isBotIntent){
                    document.querySelector('.test-btn-news').style.right="422px"
                }
            }  else {
                document.querySelector('.test-btn-news').style.right="-2px"
                // if(this.$route.name === 'botIntent'){
                //     this.isBotIntent = true;
                // }
                sessionStorage.setItem('isOpenHeathlyDrawer',false);
            }
            if (data.route && data.route === 'skill'){
                this.isBotIntent = true;
                this.changeTestContentVisible(false);
            }
        });
    },
    methods: {
        // 健康度中添加意图
        addIntent(){

        },
        changeSwitchDia(){
            this.showSwitch = !this.showSwitch;
        },
        //获取模块
        getModule() {
            this.FetchGet("/portal-api/company/getUserCompanyModule").then((res) => {
                console.log('getModule',res)
                if (res.code === "0" && res.data) {
                    let module = res.data.modules || [];
                    module.forEach((item) => {
                        if (item.sysModule.code === "WORKORDER") {
                            this.activeServiceObj.WORKORDER =
                                item.sysModule.portalUrl;
                        }
                        if (item.sysModule.code === "IM") {
                            sessionStorage.setItem('isHasImPoewr', true)
                            this.activeServiceObj.IM = item.sysModule.portalUrl;
                        }
                        if (item.sysModule.code === "MANAGEMENT") {
                            this.activeServiceObj.MANAGEMENT = item.sysModule.portalUrl;
                        }
                        if (item.sysModule.code === "KNOWLEDGE") {
                            this.activeServiceObj.KNOWLEDGE = item.sysModule.portalUrl;
                        }
                        if (item.sysModule.code === "ASKAI") {
                            this.activeServiceObj.ASKAI = item.sysModule.portalUrl;
                        }
                        if (item.sysModule.code === "QUESTION") {
                            this.activeServiceObj.QUESTION =  item.sysModule.portalUrl
                            // m.checked = true;
                        }
                    });
                }
            });
        },
        getUrl() {
            this.locationUrl = document.referrer;
            if (this.locationUrl === "http://signin.guoranbot.com/") {
                this.showBusiness = true;
            }
        },
        changeActive(routePath) {
            let paths = routePath.split("/");
            let link = paths[paths.length - 1];
            for (let i in this.navList) {
                if (this.navList[i].link === link) {
                    this.navList[i].isActive = true;
                } else {
                    this.navList[i].isActive = false;
                }
                if (
                    link === "fieldManage" ||
                    link === "formManage" ||
                    link === "addForm"
                ) {
                    for (let i in this.navList) {
                        if (this.navList[i].link === "webhook") {
                            this.navList[i].isActive = true;
                        }
                    }
                }
                if (
                    link === "botIntent" || link === "chatFlow" || link === "intentQa"
                ) {
                    for (let i in this.navList) {
                        if (this.navList[i].link === "botIndex") {
                            this.navList[i].isActive = true;
                        }
                    }
                }
                if (
                    link === "massSend" || link === "taskContent"
                ) {
                    for (let i in this.navList) {
                        if (this.navList[i].link === "massSend") {
                            this.navList[i].isActive = true;
                        }
                    }
                }
                if (
                    link === "link" || link === "botLinkSet") {
                    for (let i in this.navList) {
                        if (this.navList[i].link === "link") {
                            this.navList[i].isActive = true;
                        }
                    }
                }
            }
        },
        gotoLink(link) {
            this.isActive = link;
            sessionStorage.removeItem("saveActiveKnow");
            this.$router.push({
                name: link,
            });
        },

        handleOpen() {
            // this.isCollapse = true;
        },
        handleClose() {
            // this.isCollapse = false;
        },
        // 展示测试窗
        showTest() {
            this.testDrawerVisibleP = true;
            sessionStorage.setItem('isOpenTestDrawer',true);
            this.$eventBus.$emit("bot-heathly-visible", {
                flag:false,
                apiKey:'',
                botId:''
            });
            //this.$refs.Statistics.setTestOpen(true);
        },
        changeTestContentVisible(value) {
            this.$eventBus.$emit("test-window-visible", value);
            this.testDrawerVisibleP = value;
            sessionStorage.setItem('isOpenTestDrawer',value);
            if(value){
                this.$eventBus.$emit("bot-heathly-visible", {
                    flag:false,
                    apiKey:'',
                    botId:''
                });
            }
        },
        //获取用户的角色
        getRole() {
            /*this.$http.get('/api/account/current').then(res=>{
        if(res.data.code==='0')
        {
          this.userInfo=res.data.data
          console.log(this.userInfo)
          if(this.userInfo.roles[0]!==null)
          {
            this.roles=this.userInfo.roles[0]
          }
        }
      })*/
            /* this.roles =localStorage.getItem('roleId')
      if(this.roles !=='1' && this.roles !=='4')
      {
        for (let i=0;i<this.navList.length;i++)
        {
          if(this.navList[i].type ===2)
            this.navList[i].isHide=true
        }
      }*/
            /* let uid=localStorage.getItem('_uid')*/
            let uid = localStorage.getItem("_uid");
            this.FetchGet("/portal-api/account/appoint/" + uid).then((res) => {
                if (res.code === "0") {
                    this.userInfo = res.data;
                    localStorage.setItem("userInfo", JSON.stringify(res.data));
                    if (res.data.dingTalkLoginInfo){
                      localStorage.setItem('_corpId',res.data.dingTalkLoginInfo.corpId);
                    } else if (res.data.feishuLoginInfo){
                      localStorage.setItem('_corpId',res.data.feishuLoginInfo.tenant_key);
                    }
                }
            });
        },
        getWechatCorp() {
            let mainId = localStorage.getItem("_mainId");
            let uid = localStorage.getItem("_uid");
            let url =
                "/portal-api/account/third-wechat-corp-info?mainId=" +
                mainId +
                "&uid=" +
                uid;
            this.FetchGet(url).then((res) => {
                if (res.code === "0") {
                    if (res.data.length !== 0) {
                        localStorage.setItem('_corpId',res.data[0].corpId)
                        this.showData(res.data[0].corpId);
                    }
                }
            });
        },
        showData(id) {
            let corpId = id;
            let currentUrl = encodeURIComponent(
                window.location.origin + window.location.pathname
            );
            let url =
                " /portal-api/account/askbot-wechat-agent-config?corpId=" +
                corpId +
                "&url=" +
                currentUrl;
            this.FetchGet(url).then((res) => {
                console.debug("askbot-wechat", res.data);
                if (res.code === "0") {
                    agentConfig(corpId, res.data.data, (res) => {
                        console.info("agent auth config success", res);
                        WWOpenData.bind(this.$el);
                    });
                }
            });
        },

    },
};
</script>
<style lang="less" scoped>
@import "./../../assets/less/main/main.less";
#main {
    display: flex;
    width: calc(100vw - 68px);
    .main-container {
        flex: 1;
        width: calc(100%);
    }
}
.el-menu-vertical-demo {
    height: calc(100% - 100px);
}
.test-btn-news{
    display: inline-block;
    font-size: 12px;
    font-weight: 800;
    line-height: 14px;
    position: absolute;
    top: 47vh;
    right: -2px;
    width: 12px;
    padding: 14px 10px;
    color: white;
    border-radius: 10px 0  0 10px;
    z-index: 998;
    cursor: pointer;
    background: linear-gradient(179deg, #5f9bfc,#46b4d6);
    .test-btn-icon-outers {
        display: inline-block;
        margin-bottom: 8px;
        i {
            font-size: 16px;
        }
    }
    .test-btn-text-outers {
        display: inline-block;
        overflow: hidden;
        color: #fff;
    }
}
.test-btn-new {
    display: inline-block;
    font-size: 12px;
    font-weight: 800;
    line-height: 14px;
    position: absolute;
    top: 47vh;
    right: -2px;
    width: 12px;
    padding: 0 11px;
    color: white;
    border-radius: 21px 0 0 21px;
    z-index: 998;
    cursor: pointer;
    background: linear-gradient(179deg, #00baff, #22d8bc);
    .test-btn-icon-outer {
        display: inline-block;
        margin: 4px 0;
        height: 12px;
        line-height: 34px;
        i {
            font-size: 14px;
        }
    }
    .test-btn-text-outer {
        display: inline-block;
        height: 0;
        overflow: hidden;
        margin-top: 6px;
    }
}
.test-btn-new:hover {
    .test-btn-text-outer {
        @keyframes heightAnimate {
            0% {
                transform: translateY(0px);
                height: 0;
            }
            100% {
                transform: translateY(0px);
                height: 65px;
            }
        }
        animation: heightAnimate 0.4s ease 1;
        animation-fill-mode: forwards;
    }
    //border: solid 1px #366AFF;
}
.borderBottom {
    border-bottom: 2px solid #f5f8fb;
    line-height: 40px;
    i {
        color: #366aff;
        font-size: 16px;
        padding-right: 10px;
    }
}
.switch-system {
    flex: none;
    height: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    position: inherit;
    .switch-box {
        z-index: 9999;
        height: 200px;
        background: #fff;
        box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.13);
        border-radius: 5px;
        position: absolute;
        left: 65px;
        bottom: 20px;
        .tr-w {
            width: 16px;
            position: absolute;
            left: -10px;
            bottom: 15px;
        }
        .switch-box-close {
            position: absolute;
            right: -10px;
            top: -10px;
            display: block;
            width: 35px;
            height: 35px;
            line-height: 35px;
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.21);
            border-radius: 50%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                display: inline-block;
                margin: 0 auto;
                font-size: 18px;
                color: #616161;
            }
        }
        .switch-box-title {
            display: block;
            text-align: center;
            height: 17px;
            font-size: 14px;
            font-weight: 600;
            color: #000;
            line-height: 28px;
            margin: 16px 0 25px;
        }
        .switch-box-content {
        }
    }
}
</style>
