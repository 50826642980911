//生成从minNum到maxNum-1的随机数
function randomNum(minNum,maxNum){
    switch(arguments.length){
        case 1:
            return parseInt(Math.random()*minNum+1,10)-1;
            break;
        case 2:
            return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10)-1;
            break;
        default:
            return 0;
            break;
    }
}

export {
    randomNum
}
